import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from "i18next-chained-backend";
import resourcesToBackend from 'i18next-resources-to-backend';
import I18NextHttpBackend from 'i18next-http-backend';

const savedLanguage = localStorage.getItem('language') || 'en';

export const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
    const userId = localStorage.getItem("userId");
    if(userId) {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/changeselectedlanguage/${userId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({selectedLanguageCode: lng.toUpperCase()}),
      });
    }
};

i18n
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    lng: savedLanguage,
    fallbackLng: 'en',
    defaultNS: 'global',
    interpolation: {
      escapeValue: false,
    },
    backend: {
      backends: [
          I18NextHttpBackend,
          resourcesToBackend((language, namespace) => import(`../../locals/${language}/${namespace}.json`))
      ],
    },
  });

export default i18n;
