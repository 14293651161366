import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import './UnsubscribeFromEmails.css';
import { useTranslation } from "react-i18next";

export const UnsubscribeFromEmails = () => {

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {t} = useTranslation();
    
    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
    };

    const handleUnsubscribe = (email) => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/unsubscribeFromEmails`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`Network response was not ok, status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            console.log('Respuesta completa:', data);
            setLoading(false);
            navigate('/');
        })
        .catch((error) => {
            toast.error(t('pages.unsubscribe.could_not_complete_operation'));
            setLoading(false);
        });  
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleUnsubscribe(email);
    };
    
    return (
        <div className='login-container' style={{ backgroundColor: '#F8F4F1' }}>
        <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-4 offset-lg-4 pt-4 pb-4">
            <h2 className="mb-3" style={{ fontFamily: "'Frank Ruhl Libre', serif", color: '#396063', textAlign: 'left' }}>{t('pages.unsubscribe.title')}</h2>
            <hr />
            <form onSubmit={handleSubmit}>
                <div className="mb-3" style={{ textAlign: 'left', fontFamily: "'Frank Ruhl Libre', serif" }}>
                    <label htmlFor="emailInput">{t('pages.unsubscribe.email_label')}</label>
                    <input
                    type="text"
                    className="form-control"
                    id="emailInput"
                    placeholder={t('pages.unsubscribe.email_placeholder')}
                    value={email}
                    onChange={handleEmailChange}
                    required
                    disabled={loading}
                    />
                </div>
                <div className="d-grid gap-2">
                    <input
                    className="btn btn-primary"
                    type="submit"
                    style={{ backgroundColor: '#396063', fontFamily: "'Frank Ruhl Libre', serif" }}
                    value={t('pages.unsubscribe.unsubscribe_button')}
                    disabled={loading}
                    />
                </div>
            </form>
        </div>
        <ToastContainer />
    </div>
    )
}