import React from "react";
import SatisfactionGuaranteeIcon from '../../../assets/images/SatisfactionGuaranteeIcon.png';
import { useTranslation } from "react-i18next";

export const SatisfactionGuarantee = () => {
    const {t} = useTranslation();
    return(
        <div className="row justify-content-center" style={{ backgroundColor: '#F8F4F1' }}>
            <div className="col-12 col-sm-8 col-md-6 col-lg-4 pt-4 pb-4 text-center">
                <img src={SatisfactionGuaranteeIcon} alt="Satisfaction Guarantee" style={{ height: '100px' }} />
                <h2 style={{ fontFamily: "'Frank Ruhl Libre', serif", color: '#396063', marginTop: '2.5%' }}>{t('components.register.satisfaction_guarantee.title')}</h2>
                <p style={{ fontFamily: "'Frank Ruhl Libre', serif", fontSize: '18px' }}>{t('components.register.satisfaction_guarantee.subtitle')}</p>
            </div>
        </div>
    )    
}