import React from "react";
import { useNavigate } from "react-router-dom";
import { format, parseISO } from 'date-fns';
import { useTranslation } from "react-i18next";

export const SagaCard = ({bookId, sagaName, startDate}) => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const formattedDate = format(parseISO(startDate), 'MMMM do, yyyy');

    const handleCardClick = () => {
    navigate(`/book/${bookId}`);
    };

    return (
        <div className="card mt-4" onClick={handleCardClick} style={{ cursor: 'pointer' }}>
            <div className="card-body">
                <h5 className="card-title" style={{ fontFamily: "'Frank Ruhl Libre', serif", color: '#396063', fontSize: '24px', textAlign: 'left', marginLeft: '5%' }}>{sagaName}</h5>
                <hr style={{ margin: '0 5%'}} />
                <p className="card-text" style={{ fontFamily: "'Frank Ruhl Libre', serif", textAlign: 'left', marginLeft: '5%', fontStyle: 'italic' }}>{t('components.home_page.saga_card.started_on')} {formattedDate}</p>
            </div>
        </div>
    );
}