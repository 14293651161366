import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SagaCard } from '../../components/homePage/SagaCard';
import { useTranslation } from 'react-i18next';
import { getUserId, logoutUser } from '../../utils/authentication/cognitoUtils';

export const HomePage = () => {
    const [name, setName] = useState('');
    const [sagas, setSagas] = useState([]);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const fetchSagas = async (userId) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/books/bookpreview/${userId}`);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const sagasData = await response.json();
          setSagas(sagasData);
          //For a first version, then, remove this line
          navigate(`/book/${sagasData[0].Id}`);
        } catch (error) {
          console.error("Fetching sagas failed", error);
        }
    };

    useEffect(() => {
        const fetchUserName = async (userId) => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/usernameById/${userId}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                console.log(data);
                const username = data.name;
                setName(username);
            } catch (error) {
                console.error("Fetching username failed", error);
                logoutUser((error, message) => {
                    if (message){
                        navigate('/login');
                    }
                });
            }
        }

        getUserId((error, userId) => {
            if (error) {
                navigate('/login');
            } else {
                fetchUserName(userId);
                fetchSagas(userId);
            }
        });
    }, []);

    return (
        <div className='col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-5 offset-lg-3 pt-4 pb-4'>
            <div className='row mt-4'>
                <h2 style={{ fontFamily: "'Frank Ruhl Libre', serif", color: '#7E3D55' }}>{t('pages.home_page.welcome_message')} {name}!</h2>
            </div>

            <div className='row mt-4'>
                <h1 style={{ fontFamily: "'Frank Ruhl Libre', serif", color: '#396063', textAlign: 'left' }}>{t('pages.home_page.storytellers')}</h1>
            </div>

            {sagas.map((saga, index) => (
                <SagaCard key={index} sagaName={saga.Storyteller.Name} startDate={saga.BookEmails[0].SendOnDate} bookId={saga.Id} />
            ))}
        </div>
    );
}
