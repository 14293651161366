import React, { useState } from "react";
import { forgotPassword } from "../../utils/authentication/cognitoUtils";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import './ForgottenPassword.css';
import { useTranslation } from "react-i18next";

export const ForgottenPassword = () => {

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        forgotPassword(email, (err, data) => {
            if (err) {
                console.error(err);
                setLoading(false);
                toast.error(t('pages.forgotten_password.main.error'));
            } else {
                console.log(data);
                setLoading(false);
                navigate(`/forgottenpassword/reset/${email}`);
            }
        });
    }
    
    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
    };


    return (
        <div className='login-container' style={{ backgroundColor: '#F8F4F1' }}>
            <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-4 offset-lg-4 pt-4 pb-4">
                <h2 className="mb-3" style={{ fontFamily: "'Frank Ruhl Libre', serif", color: '#396063', textAlign: 'left' }}>{t('pages.forgotten_password.main.title')}</h2>
                <hr />
                <p style={{ fontFamily: "'Frank Ruhl Libre', serif", textAlign: 'left' }}>{t('pages.forgotten_password.main.subtitle')}</p>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3" style={{ textAlign: 'left', fontFamily: "'Frank Ruhl Libre', serif" }}>
                        <label htmlFor="emailInput">{t('pages.forgotten_password.main.email')}</label>
                        <input
                        type="text"
                        className="form-control"
                        id="emailInput"
                        placeholder={t('pages.forgotten_password.main.email_placeholder')}
                        value={email}
                        onChange={handleEmailChange}
                        required
                        disabled={loading}
                        />
                    </div>
                    <div className="d-grid gap-2">
                        <input
                        className="btn btn-primary"
                        type="submit"
                        style={{backgroundColor: '#396063', fontFamily: "'Frank Ruhl Libre', serif" }}
                        value={t('pages.forgotten_password.main.send_email_button')}
                        disabled={loading || !email}
                        />
                    </div>
                </form>
            </div>
            <ToastContainer />
        </div>
    )
}