import React, { useState } from "react";
import { SatisfactionGuarantee } from "../../components/register/SatisfactionGuarantee/SatisfactionGuarantee";
import { useNavigate } from "react-router-dom";
import './Register.css';
import { useTranslation } from "react-i18next";

export const GiftReceiverInfo = () => {

    const {t} = useTranslation();

    const [recipientName, setRecipientName] = useState(null);
    const [recipientEmail, setRecipientEmail] = useState(null);
    const [sendOnDate, setSendOnDate] = useState(null);
    const [fromName, setFromName] = useState(null);
    const [message, setMessage] = useState(null);
    const [ownBook, setOwnBook] = useState(false);
    const navigate = useNavigate();

    const today = new Date().toISOString().split('T')[0];

    const isAnyFieldEmpty = () => {
        return (recipientName == null || recipientName == '' || recipientEmail == null || recipientEmail == '' || sendOnDate == null || sendOnDate == '' || fromName == null || fromName == '' || message == null || message == '');
    }

    const onContinueClicked = () => {
        localStorage.setItem('own-book', ownBook);
        if (ownBook) {
            navigate("/register/finishregister");
        } else {
            if (!/\S+@\S+\.\S+/.test(recipientEmail)) {
                alert(t('pages.register.gift_receiver_info.invalid_email_error'));
                return;
            } else {
                const data = {
                    recipientName,
                    recipientEmail,
                    sendOnDate,
                    fromName,
                    message
                };
                localStorage.setItem('form-data', JSON.stringify(data));
                navigate("/register/finishregister");
            }
        }
    }

    return (
        <div className="login-container">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-8 col-md-6 col-lg-4 pt-4 pb-4" style={{ textAlign: 'left', fontFamily: "'Rubik', serif" }}>
                    <h2 className="mb-3" style={{ color: '#396063', fontFamily: "'Frank Ruhl Libre', serif" }}>{t('pages.register.gift_receiver_info.title')}</h2>
                    <hr />
                    <div className="form-check mb-3">
                        <input type="checkbox" className="form-check-input" id="chkOwnBook" checked={ownBook} onChange={(e) => setOwnBook(e.target.checked)} />
                        <label htmlFor="chkOwnBook" className="form-check-label" style={{ fontSize: '12px' }}>{t('pages.register.gift_receiver_info.own_book')}</label>
                    </div>
                    {(!ownBook) &&
                    (
                    <div>
                        <div className="mb-3">
                            <label htmlFor="recipientNameInput" className="form-label" style={{ fontSize: '18px' }}>{t('pages.register.gift_receiver_info.fullname_label')}</label>
                            <input
                                type="text"
                                className="form-control"
                                id="recipientNameInput"
                                placeholder={t('pages.register.gift_receiver_info.fullname_label')}
                                value={recipientName}
                                onChange={(e) => setRecipientName(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="recipientEmailInput" className="form-label" style={{ fontSize: '18px' }}>{t('pages.register.gift_receiver_info.email_label')}</label>
                            <p style={{ fontStyle: 'italic', fontSize: '14px' }}>{t('pages.register.gift_receiver_info.email_paragraph')}</p>
                            <input
                                type="text"
                                className="form-control"
                                id="recipientEmailInput"
                                placeholder={t('pages.register.email_placeholder')}
                                value={recipientEmail}
                                onChange={(e) => setRecipientEmail(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="sendOnDateInput" className="form-label" style={{ fontSize: '18px' }}>{t('pages.register.gift_receiver_info.send_gift_on')}</label>
                            <input
                                type="date"
                                className="form-control"
                                id="sendOnDateInput"
                                min={today}
                                value={sendOnDate}
                                onChange={(e) => setSendOnDate(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="giftMessage" className="form-label" style={{ fontSize: '18px' }}>{t('pages.register.gift_receiver_info.gift_message_from_label')}</label>
                            <input
                                type="text"
                                className="form-control"
                                id="giftMessage"
                                placeholder={t('pages.register.gift_receiver_info.gift_message_from_placeholder')}
                                value={fromName}
                                onChange={(e) => setFromName(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="messageInput" className="form-label" style={{ fontSize: '18px' }}>{t('pages.register.gift_receiver_info.gift_message_label')}</label>
                            <textarea
                                className="form-control"
                                id="messageInput"
                                placeholder={t('pages.register.gift_receiver_info.gift_message_placeholder')}
                                rows="5"
                                style={{borderRadius: '10px'}}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </div>
                        </div>
                        )}
                        <button className="btn btn-primary mt-2" type="submit" style={{ backgroundColor: '#396063', fontFamily: "'Rubik', serif", width: '100%', borderRadius: '10px' }} disabled={isAnyFieldEmpty() && !ownBook} onClick={onContinueClicked}>{t('pages.register.continue_button')}</button>
                    </div>
            </div>
            <SatisfactionGuarantee />
        </div>
    )
}