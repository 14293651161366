import React, {useEffect, useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './LogoHeader.css';
import {ReactComponent as LogoSVG} from '../../../assets/icons/logo_maroon.svg'
import { useTranslation } from 'react-i18next';
import { changeLanguage } from '../../../utils/translation/translationUtils';
import {ReactComponent as IconEN} from '../../../assets/icons/EN.svg';
import {ReactComponent as IconES} from '../../../assets/icons/ES.svg';
import {ReactComponent as IconNO} from '../../../assets/icons/NO.svg';
import {ReactComponent as IconSV} from '../../../assets/icons/SV.svg';
import {ReactComponent as IconIT} from '../../../assets/icons/IT.svg';
import { getUserId, logoutUser } from '../../../utils/authentication/cognitoUtils';

export const LogoHeader = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [userFullName, setUserFullName] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const languageOptions = [
        { code: 'en', name: 'English', icon: <IconEN /> },
        { code: 'es', name: 'Español', icon: <IconES /> },
        { code: 'no', name: 'Norsk', icon: <IconNO /> },
        { code: 'sv', name: 'Svenska', icon: <IconSV />},
        { code: 'it', name: 'Italiano', icon: <IconIT />}
    ];

    const getSelectedLanguage = () => {
       return localStorage.getItem('language');
    }

    const isAuthPage = location.pathname === '/login' || location.pathname.match('/register') || location.pathname.match('/forgottenpassword') || location.pathname.match('/unsubscribe');

    const isContactPage = location.pathname === '/contactus';

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    
    const goToLogin = () => {
        navigate('/login');
    };

    const selectLanguage = (languageCode) => {
        changeLanguage(languageCode.toLowerCase());
    }

    const logout = (e) => {
        e.preventDefault();

        logoutUser((error, message) => {
            if(message) {
                goToLogin();
            }
        });
        setIsMenuOpen(false);
    };

    useEffect(() => {
        const fetchUserName = async (userId) => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/usernameById/${userId}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                const username = data.name;
                setUserFullName(username);
            } catch (error) {
                console.error("Fetching username failed", error);
                logoutUser((error, message) => {
                    if(message) {
                        goToLogin();
                    }
                });
                goToLogin();
            }
        }

        if (!isAuthPage) {
            getUserId((error, userId) => {
                if (error) {
                    if (!isContactPage) {
                        goToLogin(); 
                    }      
                } else {
                    fetchUserName(userId);
                }
            });
        }
    }, [localStorage.getItem('userId')]);

    return (
        <section>
            <nav className="navbar navbar-expand-lg navbar-light bg-light" style={{ justifyContent: 'space-around', alignItems: 'center'}}>
      
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <a href='/'><LogoSVG style={{ marginLeft: '10px', height: '36px', width: 'auto' }} /></a>
                </div>

                {(
                    <div>
                    {localStorage.getItem('userId') && userFullName ? (
                            <button className="user-circle" onClick={toggleMenu}>
                                <span className="user-initials">{userFullName.split(' ')[0].charAt(0)}{userFullName.split(' ')[userFullName.split(' ').length - 1].charAt(0)}</span>
                                <span className="user-name">{userFullName}</span>
                            </button>
                    ) : (
                        <button className="navbar-toggler" type="button" onClick={toggleMenu}>
                        <span className="navbar-toggler-icon"></span>
                        </button>
                    )}
                    </div>
                )}

            </nav>
            <hr className='mt-0 mb-0'/>
            {isMenuOpen &&
                <div>
                    <div className="burger-button-menu">
                        <ul style={{listStyleType: 'none', fontFamily: "'Rubik', serif", color: '#7E3D55', padding: '20px 40px'}}>
                            <li className='text-center'><a href='/contactus' style={{textDecoration: 'none', color: '#7E3D55'}}>{t('components.common.logo_header.menu.contact_us')}</a></li>
                            <li className='text-center'><hr/></li>
                            {(localStorage.getItem('userId') != null) && 
                                <div>
                                    <li className='text-center'><a href='#' onClick={logout} style={{textDecoration: 'none', color: '#7E3D55'}}>{t('components.common.logo_header.menu.log_out')}</a></li>
                                    <li className='text-center'><hr/></li>
                                </div>
                            }
                            <li className='text-center language-selector-button'>
                                {languageOptions.map((option) => (
                                    <button 
                                        key={option.code}
                                        onClick={() => selectLanguage(option.code)}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontFamily: "'Rubik', serif",
                                            fontSize: '1rem',
                                            flexBasis: '33.33%',
                                            color: '#7E3D55',
                                            textDecoration: (getSelectedLanguage() == option.code || (getSelectedLanguage() == null && option.code === 'en')) ? 'underline' : 'none'
                                        }}>
                                        {option.icon} {option.name}
                                    </button>
                                ))}
                            </li>
                        </ul>
                    </div>
                </div>
            }
        </section>
    );
};