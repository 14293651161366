import React, { useState, useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import './QuestionCard.css';
import { useNavigate } from 'react-router-dom';

export const QuestionCard = ({ question, index, deleteQuestion, markQuestionCompleted, additionalContent, isDraggable }) => {
    const { t } = useTranslation();
    const [openMenuId, setOpenMenuId] = useState(null);
    const navigate = useNavigate();

    const toggleMenu = () => {
        setOpenMenuId(openMenuId === question.Id ? null : question.Id);
    };

    useEffect(() => {
        const closeDropdown = (event) => {
            if (!event.target.matches('.dropdown')) {
                setOpenMenuId(null);
            }
        };

        document.addEventListener('click', closeDropdown);
        return () => document.removeEventListener('click', closeDropdown);
    }, []);

    const handleCardClick = (event) => {
        if (!event.target.closest('.dropdown, .menu-content, .dateSpan, .dateInput')) {
          navigate(`/question/edit/${question.Id}`);
        }
      };

    return (
        (isDraggable) ? (
            <div onClick={handleCardClick}>
            <Draggable key={question.Id} draggableId={question.Id.toString()} index={index}>
              {(draggableProvided, snapshot) => (
                <li {...draggableProvided.draggableProps} ref={draggableProvided.innerRef} {...draggableProvided.dragHandleProps} className="draggable-question-item draggable" style={{
                  ...draggableProvided.draggableProps.style,
                  backgroundColor: snapshot.isDragging ? '#f4f4f4' : 'white'
                }}>
                  <div className='row draggable-question'>
                    <div className='col-2 col-lg-1 align-self-center text-end'>
                        <a className={`grip-dots ${snapshot.isDragging || openMenuId === question.Id ? '' : 'hiddable-grip-dots'}`}><i className='fas fa-ellipsis-vertical fa-sm'/><i className='fas fa-ellipsis-vertical fa-sm'/></a>
                        <span className='question-number' style={{ fontFamily: "'Frank Ruhl Libre', serif", color: '#396063', fontSize: '18px'}}>{index + 1}</span>
                    </div>
                    <div className='col-10 col-md-10 col-lg-11 draggable-question-content pt-3 pb-3'>
                        <div className='row'>
                            <div className='col-10 col-md-10 col-sm-10 col-lg-11'>
                            <span style={{ fontFamily: "'Rubik', serif"}}>{question.Question}</span>
                            </div>
                            <div className='col-2 col-md-2 col-sm-2 col-lg-1'>
                            <a style={{ fontFamily: "'Frank Ruhl Libre', serif", fontSize: '14px', color: '#396063'}} onClick={() => toggleMenu(question.Id)}><i className='fas fa-ellipsis dropdown'/></a>
                            {openMenuId === question.Id && (
                                <div className="menu-content">
                                <a href={`/question/edit/${question.Id}`}><i className='fas fa-pencil fa-sm' style={{marginRight:'5px'}}/>{t('components.question_dashboard.question_list.edit_question')}</a>
                                <a href='#' onClick={() => deleteQuestion(question.Id)}><i className='fas fa-trash fa-sm' style={{marginRight:'5px'}}/>{t('components.question_dashboard.question_list.remove_question')}</a>
                                </div>
                            )}
                            </div>
                        </div>
                        {additionalContent(question)}
                    </div>
                  </div>
                </li>
              )}
            </Draggable>
            </div>
        ) : (
            <div onClick={handleCardClick}>
                <li className="question-item" style={{ backgroundColor: 'white'}}>
                    <div className='row draggable-question'>
                        <div className='col-2 col-lg-1 align-self-center text-end'>
                            <span className='question-number' style={{ fontFamily: "'Frank Ruhl Libre', serif", color: '#396063', fontSize: '18px'}}>{index + 1}</span>
                        </div>
                        <div className='col-10 col-md-10 col-lg-11 question-content pt-3 pb-3'>
                            <div className='row'>
                                <div className='col-10 col-md-10 col-sm-10 col-lg-11'>
                                    <span style={{ fontFamily: "'Rubik', serif"}}>{question.Question}</span>
                                </div>
                                <div className='col-2 col-md-2 col-sm-2 col-lg-1'>
                                    <a style={{ fontFamily: "'Frank Ruhl Libre', serif", fontSize: '14px', color: '#396063', textDecoration: 'underline' }} onClick={toggleMenu}><i className='fas fa-ellipsis dropdown'/></a>
                                    {openMenuId === question.Id && (
                                        <div className="menu-content">
                                            {(question.State == 'Draft') && 
                                            <a href='#' onClick={() => markQuestionCompleted(question.Id)}><i className='fas fa-check fa-sm' style={{marginRight:'5px'}}/>{t('components.question_dashboard.question_list.mark_question_complete')}</a>
                                            }
                                            <a href={`/question/edit/${question.Id}`}><i className='fas fa-pencil fa-sm' style={{marginRight:'5px'}}/>{t('components.question_dashboard.question_list.edit_question')}</a>
                                            <a href='#' onClick={() => deleteQuestion(question.Id)}><i className='fas fa-trash fa-sm' style={{marginRight:'5px'}}/>{t('components.question_dashboard.question_list.remove_question')}</a>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {additionalContent(question)}
                        </div>
                    </div>
                </li>
            </div>
        )
    );
};
