import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { registerUser } from "../../utils/authentication/cognitoUtils";
import './Register.css';
import { useTranslation } from "react-i18next";

export const ConfirmEmailGiftReceiver = () => {
    let {userId} = useParams();
    const navigate = useNavigate();
    const [userInDB, setUserInDB] = useState(null);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [receiveOffers, setReceiveOffers] = useState(false);
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();

    const handleFullNameChange = (e) => {
        const newFullName = e.target.value;
        setFullName(newFullName);
    };
    
    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
        setConfirmPassword(newConfirmPassword);
    };

    useEffect(() => {
        const fetchUserName = async (userId) => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/byId/${userId}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setUserInDB(data);
            } catch (error) {
                console.error("Fetching user failed", error);
                toast.error(t('pages.register.unknown_error'));
            }
        }
    
        fetchUserName(userId);
    }, [userId]);
    
    useEffect(() => {
        console.log(userInDB); 
        setFullName(userInDB?.Name || '');
        setEmail(userInDB?.Email || '');
    }, [userInDB]);

    
    const handleSubmit = (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            toast.error(t('pages.register.passwords_not_match'));
            return;
        }
        if (!/\S+@\S+\.\S+/.test(email)) {
            toast.error(t('pages.register.insecure_password'));
            return;
        }
        if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(password)) {
            toast.error(t('pages.register.insecure_password'));
            return;
        }
        setLoading(true);
        registerUser(fullName, email, password, (err, result) => {
            if (err) {
                console.error(err);
                setLoading(false);
                if (err.code === 'UsernameExistsException') {
                    toast.error(t('pages.register.username_exists_error'));
                } else {
                    toast.error(t('pages.register.unknown_error_registering'));
                }
            } else {
                console.log(result);

                const cognitoId = result.userSub;

                const userData = {
                    name: fullName,
                    email: email,
                    cognitoId: cognitoId,
                    sendEmails: receiveOffers ? 1 : 0,
                    userId: userId
                };

                console.log(userData);
  
                fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/finishregister`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(userData),
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(async data => {
                    console.log('Success:', data);
                    setLoading(false);
                    localStorage.setItem('userId', userId);
                    navigate('/');
                })
                .catch((error) => {
                    console.error('Error:', error);
                    toast.error(t('pages.register.confirm_email_gift_receiver.call_an_administrator'));
                    setLoading(false);
                });
            }
        });
    };

    return(
        <div className="row mt-4 login-container">
            {(userInDB && !userInDB.CognitoId) && (
                <div>
                    <h2 style={{fontFamily: "'Frank Ruhl Libre', serif", color: '#396063', fontWeight:'bold'}}>{t('pages.register.confirm_email_gift_receiver.title')} {userInDB.Name}!</h2>
                    <p>{t('pages.register.confirm_email_gift_receiver.subtitle')}</p>

                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-8 col-md-6 col-lg-4 pt-4 pb-4" style={{ textAlign: 'left', fontFamily: "'Rubik', serif" }}>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="fullNameInput" className="form-label" style={{ fontSize: '18px' }}>{t('pages.register.your_full_name_label')}</label>
                                    <input type="text" className="form-control" id="fullNameInput" placeholder={t('pages.register.fullname_placeholder')} required value={fullName} onChange={handleFullNameChange} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="emailInput" className="form-label" style={{ fontSize: '18px' }}>{t('pages.register.your_email_address_label')}</label>
                                    <input type="text" className="form-control" id="emailInput" placeholder={t('pages.register.email_placeholder')} required value={email} onChange={handleEmailChange} />
                                    <div className="form-check mt-2">
                                        <input type="checkbox" className="form-check-input" id="receiveOffers" checked={receiveOffers} onChange={(e) => setReceiveOffers(e.target.checked)} />
                                        <label htmlFor="receiveOffers" className="form-check-label" style={{ fontSize: '12px' }}>{t('pages.register.want_receive_offers')}</label>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="passwordInput">{t('pages.register.password_label')}</label>
                                    <input type="password" className="form-control" id="passwordInput" placeholder={t('pages.register.password_label')} value={password} onChange={handlePasswordChange} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="confirmPasswordInput">{t('pages.register.confirm_password_label')}</label>
                                    <input type="password" className="form-control" id="confirmPasswordInput" placeholder={t('pages.register.confirm_password_label')} value={confirmPassword} onChange={handleConfirmPasswordChange} required />
                                </div>
                                <div className="container p-1 text-center" style={{ backgroundColor: '#E9E2DA' }}>
                                    <p>{t('pages.register.privacy_label')} <a href="/terms">{t('pages.register.terms_service')}</a> {t('pages.register.and')} <a href="/privacy">{t('pages.register.privacy_policy')}</a>.</p>
                                </div>
                                <button className="btn btn-primary mt-2" type="submit" style={{ backgroundColor: '#396063', fontFamily: "'Rubik', serif", width: '100%', borderRadius: '10px' }}>{t('pages.register.confirm_email_gift_receiver.finish_register_button')}</button>
                            </form>
                        </div>
                    </div>                  
                </div>
                
            )}
            {(!userInDB) && (
                <div>
                    <h2 style={{fontFamily: "'Frank Ruhl Libre', serif", color: '#396063', fontWeight:'bold'}}>{t('pages.register.confirm_email_gift_receiver.error_title')}</h2>
                    <p style={{fontFamily: "'Rubik', serif", color: '#396063'}}>{t('pages.register.confirm_email_gift_receiver.no_user_in_db_subtitle')}</p>
                </div>
            )}
            {(userInDB && userInDB.CognitoId) && (
                <div>
                    <h2 style={{fontFamily: "'Frank Ruhl Libre', serif", color: '#396063', fontWeight:'bold'}}>{t('pages.register.confirm_email_gift_receiver.error_title')}</h2>
                    <p style={{fontFamily: "'Rubik', serif", color: '#396063'}}>{t('pages.register.confirm_email_gift_receiver.user_already_registered_subtitle')} <a href="/login">{t('pages.register.confirm_email_gift_receiver.here')}</a> {t('pages.register.confirm_email_gift_receiver.if_error_call_an_administrator')}</p>
                </div>
            )}
            <ToastContainer/>
        </div>
    );
}