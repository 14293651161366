import React, { useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { QuestionCard } from './QuestionCard/QuestionCard';


export const QuestionList = ({ questions, deleteQuestion, activeTab, reorderQuestions, updateQuestionsDate, toastError, toastSuccess, markQuestionCompleted }) => {

  const {t} = useTranslation();
  const [editingDateQuestionId, setEditingDateQuestionId] = useState(null);
  const [newDate, setNewDate] = useState('');


  const handleDateClick = (questionId, currentIsoDate) => {
    setEditingDateQuestionId(questionId);
    setNewDate(currentIsoDate);
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(questions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    reorderQuestions(items);
    
    const questionOrder = items.map((item, index) => [index + 1, item.Id]);

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/questions/reorder`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ questionOrder }),
        });

        if (!response.ok) {
          throw new Error('Something went wrong while reordering the questions');
        }

        toastSuccess(t('components.question_dashboard.question_list.not_started.reorder_success'));
      } catch (error) {
        console.error('Failed to reorder questions: ', error);
        toastError(t('components.question_dashboard.question_list.not_started.reorder_fail'));
      }
    };

    const updateQuestionDate = async (questionId, newDate) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/questions/updateDate/${questionId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ newMailDeliveryDate: newDate.slice(0, 19).replace('T', ' ') }),
        });
    
        if (!response.ok) {
          throw new Error('Failed to update the scheduled date');
        }
    
        await response.json();
        toastSuccess(t('components.question_dashboard.question_list.not_started.date_updated_success'));
        updateQuestionsDate(questionId, newDate);
        setEditingDateQuestionId(null);
        setNewDate('');
      } catch (error) {
        console.error('Error updating the date:', error);
        throw new Error(error);
      }
    };

    const handleSelectDate = async (e) => {
      const selectedDate = e.target.value;
    
      const date = new Date(selectedDate);
    
      const utcDate = Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + 1,
        3
      );
    
      const isoDate = new Date(utcDate).toISOString();
    
      setNewDate(isoDate);
    
      try {
        await updateQuestionDate(editingDateQuestionId, isoDate);
        updateQuestionsDate(editingDateQuestionId, isoDate);
      } catch(err) {
        console.log(err);
        toastError(t('components.question_dashboard.question_list.not_started.date_updated_fail'));
      }
    };

  const convertISOToDate = (isoString) => {
    const date = new Date(isoString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().substr(-2);
  
    return `${day}/${month}/${year}`;
  };

  const additionalContent = (question) => {
    if (question.State.toUpperCase() === 'NOT STARTED') {
      return (
        <div className='row mt-1'>
          <div className='col-12 col-md-12'>
            {editingDateQuestionId === question.Id ? (
              <input
              type="date"
              value={newDate.split('T')[0]}
              className='dateInput'
              onChange={handleSelectDate}
              min={new Date().toISOString().split('T')[0]}
              />
            ) : (
              question.Sent ? (
                <span style={{ fontFamily: "'Frank Ruhl Libre', serif", fontSize: '12px', color: '#8F8F8F' }}>
                  {t('components.question_dashboard.question_list.completed.asked_on')} {convertISOToDate(question.MailDeliveryDate)}
                </span>
              ) : (
                <span className='dateSpan' style={{ fontFamily: "'Frank Ruhl Libre', serif", fontSize: '12px', color: '#8F8F8F' }} onClick={() => handleDateClick(question.Id, question.MailDeliveryDate)}>
                  {t('components.question_dashboard.question_list.not_started.scheduled_for')} {convertISOToDate(question.MailDeliveryDate)}
                </span>
              )
            )}
          </div>
        </div>
      )
    } else {
      return (
        <span style={{ fontFamily: "'Frank Ruhl Libre', serif", fontSize: '12px', color: '#8F8F8F' }}>
          {t('components.question_dashboard.question_list.completed.asked_on')} {convertISOToDate(question.MailDeliveryDate)}
        </span>
      );
    }
  };

  return (
    (activeTab !== 'Not started') ? (
      <div>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-8 col-lg-6' style={{ textAlign: 'left' }}>
            <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
              {questions?.map((question, index) => (
                <QuestionCard
                  question={question}
                  index={index}
                  deleteQuestion={deleteQuestion}
                  markQuestionCompleted={markQuestionCompleted}
                  additionalContent={() => additionalContent(question)}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    ) : (
      <div>
        <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
          <div className='row justify-content-center'>
            <div className='col-12 col-md-8 col-lg-6' style={{ textAlign: 'left' }}>
              <Droppable droppableId='questions'>
                {(droppableProvided) => (
                  <ul {...droppableProvided.droppableProps} ref={droppableProvided.innerRef} style={{ listStyleType: 'none', paddingLeft: '0px' }}>
                    {questions?.map((question, index) => (
                      <QuestionCard
                      question={question}
                      index={index}
                      deleteQuestion={deleteQuestion}
                      markQuestionCompleted={markQuestionCompleted}
                      additionalContent={() => additionalContent(question)}
                      isDraggable={true}
                    />
                    ))}
                    {droppableProvided.placeholder}
                  </ul>
                )}
              </Droppable>
            </div>
          </div>
        </DragDropContext>
      </div>
    )
  );
};
