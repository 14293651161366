import React, { useState } from "react";
import './ContactUs.css';
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";

export const ContactUs = () => {
    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const {t} = useTranslation();

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/commonsettings/contactUsMessage`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ firstName: firstName, Email: email, Message: message }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`Network response was not ok, status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            toast.success(t('pages.contact_us.message_success_alert'));
            setSuccess(true);
        })
        .catch((error) => {
            console.error('Error while sending message by contact us form:', error);
            toast.error(t('pages.contact_us.message_error'));
            setLoading(false);
        });
    }

    const handleFirstNameChange = (e) => {
        const newFirstName = e.target.value;
        setFirstName(newFirstName);
    }
    
    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
    }

    return (
        <div className="contact-us-container">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-8 col-md-6 col-lg-4 pt-4 pb-4" style={{ textAlign: 'left', fontFamily: "'Rubik', serif" }}>
                    <h2 className="mb-3" style={{ color: '#396063', textAlign: 'center', fontFamily: "'Frank Ruhl Libre', serif" }}>{t('pages.contact_us.title')}</h2>
                    <hr />
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3" style={{ textAlign: 'left', fontFamily: "'Rubik', serif" }}>
                            <label htmlFor="nameInput">{t('pages.contact_us.first_name_label')}*</label>
                            <input
                            type="text"
                            className="form-control mt-2"
                            id="nameInput"
                            placeholder={t('pages.contact_us.first_name_placeholder')}
                            style={{borderRadius: '10px'}}
                            value={firstName}
                            onChange={handleFirstNameChange}
                            required
                            disabled={loading}
                            />
                        </div>
                        <div className="mb-3" style={{ textAlign: 'left', fontFamily: "'Rubik', serif" }}>
                            <label htmlFor="emailInput">{t('pages.contact_us.email_label')}*</label>
                            <input
                            type="email"
                            className="form-control mt-2"
                            id="emailInput"
                            placeholder={t('pages.contact_us.email_placeholder')}
                            style={{borderRadius: '10px'}}
                            value={email}
                            onChange={handleEmailChange}
                            required
                            disabled={loading}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="messageInput" className="form-label" style={{ fontSize: '18px' }}>{t('pages.contact_us.message_label')}*</label>
                            <textarea
                                className="form-control"
                                id="messageInput"
                                placeholder={t('pages.contact_us.message_placeholder')}
                                rows="5"
                                style={{borderRadius: '10px'}}
                                value={message}
                                disabled={loading}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </div>
                        {success ? 
                            (
                                <div className="d-grid gap-2">
                                    <span style={{textAlign: 'center', color: '#6FC56D'}}>
                                        {t('pages.contact_us.message_success_label_first_line')}
                                        <br/>
                                        {t('pages.contact_us.message_success_label_second_line')}
                                    </span>
                                </div>
                            ) : (
                                <div className="d-grid gap-2">
                                    <input
                                    className="btn btn-primary"
                                    type="submit"
                                    style={{ backgroundColor: '#396063', fontFamily: "'Rubik', serif", borderRadius: "10px"}}
                                    value={t('pages.contact_us.send_button')}
                                    disabled={loading}
                                    />
                                </div>
                            )}
                    </form>
                </div>
            </div>
            <ToastContainer/>
        </div>
        )
}