import { CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk';
import {jwtDecode} from "jwt-decode";

AWS.config.update({ region: 'sa-east' });

const userPoolData = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_CLIENT_ID
  };
  
const userPool = new CognitoUserPool(userPoolData);

export function registerUser(fullName, email, password, callback) {
    const attributeList = [
        new CognitoUserAttribute({
            Name: 'email',
            Value: email
        }),
        new CognitoUserAttribute({
            Name: 'name',
            Value: fullName
        }),
      ];
      
      userPool.signUp(email, password, attributeList, null, function(err, result) {
        if (err) {
          console.log(err.message || JSON.stringify(err));
          callback(err, null);
          return;
        }
        const cognitoUser = result.user;
        console.log('Nombre de usuario es ' + cognitoUser.getUsername());
        callback(null, result);
      }); 
}

export function loginUser(email, password, callback) {
    const authenticationData = {
        Username: email,
        Password: password,
      };
      const authenticationDetails = new AuthenticationDetails(authenticationData);
      
      const userData = {
        Username: email,
        Pool: userPool
      };
      const cognitoUser = new CognitoUser(userData);
      
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
            const idToken = result.getIdToken().getJwtToken();
            const payload = jwtDecode(idToken);
            const cognitoId = payload.sub;
            
            console.log('Login exitoso, userSub:', cognitoId);
            console.log('Preparando para hacer la solicitud fetch con el Cognito ID:', cognitoId);
                fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/getUserId`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ cognitoId }),
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Network response was not ok, status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    console.log('Respuesta completa:', data);
                    console.log('ID de usuario obtenido:', data.userId);
                    localStorage.setItem('userId', data.userId);
                    callback(null, 'success');
                })
                .catch((error) => {
                    console.error('Error al obtener el ID de usuario:', error);
                    logoutUser(null);
                    callback('dbFailure', null);
                });
        },
        onFailure: (err) => {
            console.error('Login fallido:', err);
            callback('cognitoFailure', null);
        }, 
        newPasswordRequired: (userAttributes, requiredAttributes) => {
            delete userAttributes.email_verified;
            delete userAttributes.name;
            
            callback({newPasswordRequired: true, userAttributes, requiredAttributes, user: cognitoUser}, null);
        }
      });
}

export function finishChangePasswordRequired(cognitoUser, newPassword, newName, callback) {
    if (typeof callback === 'function') { 
        console.log(cognitoUser);
        cognitoUser.completeNewPasswordChallenge(newPassword, {'name' : newName}, {
            onSuccess: (result) => {
                callback(null, result);
            },
            onFailure: (err) => {
                callback(err, null);
            }
        });
    } else {
        console.log("callback is not a function");
    }
}

export function logoutUser(callback) {
  const cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
      cognitoUser.signOut();

      localStorage.removeItem('userId');
      console.log('Closed session correctly!');
      if (callback) {
          callback(null, 'success');
      }
  } else {
      console.error('Could not close session.');
      if (callback) {
          callback('error', null);
      }
  }
}

export function getUserId(callback) {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
        cognitoUser.getSession((err, session) => {
            if (err) {
                console.error('Error obtaining session:', err);
                logoutUser();
                if (callback) {
                    callback(err, null);
                }
                return;
            }

            if (session.isValid()) {
                const idToken = session.getIdToken().getJwtToken();
                const payload = jwtDecode(idToken);
                const cognitoId = payload.sub;

                console.log('User sub obtained successfully:', cognitoId);
                console.log('Preparando para hacer la solicitud fetch con el Cognito ID:', cognitoId);
                fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/getUserId`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ cognitoId }),
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Network response was not ok, status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    console.log('Respuesta completa:', data);
                    console.log('ID de usuario obtenido:', data.userId);
                    localStorage.setItem('userId', data.userId);
                    if (callback) {
                      callback(null, data.userId);
                    }
                })
                .catch((error) => {
                    console.error('Error al obtener el ID de usuario:', error);
                    logoutUser(null);
                    if (callback) {
                      callback('dbFailure', null);
                    }
                });
            } else {
                console.log('Invalid session');
                logoutUser();
                if (callback) {
                    callback('invalid session', null);
                }
            }
      });
  } else {
        console.log('Session with no');
        if (callback) {
            callback('session with no user', null);
        }
  }
}


export function forgotPassword(email, callback) {
  const userData = {
      Username: email,
      Pool: userPool
  };
  const cognitoUser = new CognitoUser(userData);

  cognitoUser.forgotPassword({
      onSuccess: (data) => {
          console.log('Código de restablecimiento enviado: ', data);
          callback(null, data);
      },
      onFailure: (err) => {
          console.error('Error al solicitar el restablecimiento de contraseña: ', err);
          callback(err, null);
      }
  });
}

export function confirmNewPassword(email, verificationCode, newPassword, callback) {
  const userData = {
      Username: email,
      Pool: userPool
  };
  const cognitoUser = new CognitoUser(userData);

  cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: () => {
          console.log('Restablecimiento de contraseña exitoso.');
          callback(null, 'Restablecimiento de contraseña exitoso.');
      },
      onFailure: (err) => {
          console.error('Error al restablecer la contraseña: ', err);
          callback(err, null);
      }
  });
}
