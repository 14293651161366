import React from 'react';
import './Header.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Header = ({ activeTab, onTabChange, sagaName, bookId, questionsCount}) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const redirectToAddQuestionMenu = () => {
    navigate("/question/add/" + bookId);
  }

  return (
    <header>
      <div className='row justify-content-center px-md-3 px-lg-4 pt-4'>
        <div className='col-12 col-md-8 col-lg-6'>
          <div className='row'>
            <h2 style={{ fontFamily: "'Frank Ruhl Libre', serif", color: '#396063', fontWeight: 'bold', textAlign: 'left'}}>
              <a>{sagaName}</a>
            </h2>
          </div>
          <div className='row justify-content-center'>
            <button className='btn btn-light mb-3' onClick={redirectToAddQuestionMenu} style={{ backgroundColor: '#F8F4F1', color: '#396063', fontFamily: "'Frank Ruhl Libre', serif", width: '97%', textAlign:'left', fontSize:'23px', height: '59px', borderRadius: '14px'}}><i className='fas fa-pencil-square' style={{marginRight:'10px'}}/>{t('components.question_dashboard.header.add_question')}</button>
          </div>
          <div className='row'>
            <h3 style={{ fontFamily: "'Frank Ruhl Libre', serif", color: '#396063', textAlign: 'left'}}>
            {t('components.question_dashboard.header.your_sagas')}
            </h3>
          </div>
          <div className='row'>
              <div key={'Not started'} className='col-4 col-lg-3'>
                <a
                  onClick={() => onTabChange('Not started')}
                  className={`tab-item text-center d-block ${activeTab === 'Not started' ? 'active' : ''}`}
                  aria-selected={activeTab === 'Not started'}
                  role="tab"
                >
                   <i className='far fa-clock fa-sm' style={{color: '#396063'}} /> {`${t('components.question_dashboard.header.tabs.not_started')} (${questionsCount.notStarted})`}
                </a>
              </div>
              <div key={'Draft'} className='col-4 col-lg-3'>
                <a
                  onClick={() => onTabChange('Draft')}
                  className={`tab-item text-center d-block ${activeTab === 'Draft' ? 'active' : ''}`}
                  aria-selected={activeTab === 'Draft'}
                  role="tab"
                >
                   <i className='fas fa-pencil fa-sm' style={{color: '#396063'}}/> {`${t('components.question_dashboard.header.tabs.drafts')} (${questionsCount.drafts})`}
                </a>
              </div>
              <div key={'Completed'} className='col-4 col-lg-3'>
                <a
                  onClick={() => onTabChange('Completed')}
                  className={`tab-item text-center d-block ${activeTab === 'Completed' ? 'active' : ''}`}
                  aria-selected={activeTab === 'Completed'}
                  role="tab"
                >
                  <i className='fas fa-check' style={{color: '#396063'}}/> {`${t('components.question_dashboard.header.tabs.completed')} (${questionsCount.completed})`}
                </a>
              </div>
          </div>
        </div>
        <hr className='mt-1 mb-2'/>
      </div>
    </header>
  );
};