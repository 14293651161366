import React, { useState } from "react";
import { SatisfactionGuarantee } from "../../components/register/SatisfactionGuarantee/SatisfactionGuarantee";
import { registerUser } from "../../utils/authentication/cognitoUtils";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import './Register.css';
import { useTranslation } from "react-i18next";


export const FinishRegister = () => {
    const [amountOfBooks, setAmountOfBooks] = useState(1);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [receiveOffers, setReceiveOffers] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {t} = useTranslation();

    const handleFullNameChange = (e) => {
        const newFullName = e.target.value;
        setFullName(newFullName);
    };
    
    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
        setConfirmPassword(newConfirmPassword);
    };

    const createBook = async (formData) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/books/createBook`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();
            console.log('Book creation success:', data);
        } catch (error) {
            console.error('Error during book creation:', error);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            toast.error(t('pages.register.passwords_not_match'));
            return;
        }
        if (!/\S+@\S+\.\S+/.test(email)) {
            toast.error(t('pages.register.invalid_email'));
            return;
        }
        if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(password)) {
            toast.error(t('pages.register.insecure_password'));
            return;
        }
        setLoading(true);
        registerUser(fullName, email, password, (err, result) => {
            if (err) {
                console.error(err);
                setLoading(false);
                if (err.code === 'UsernameExistsException') {
                    toast.error(t('pages.register.username_exists_error'));
                } else {
                    toast.error(t('pages.register.unknown_error_registering'));
                }
            } else {
                console.log(result);

                const cognitoId = result.userSub;

                const userData = {
                    name: fullName,
                    email: email,
                    cognitoId: cognitoId,
                    sendEmails: receiveOffers ? 1 : 0
                };

                console.log(userData);
  
                fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/register`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(userData),
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(async data => {
                    console.log('Success:', data);
                    setLoading(false);
                    try {
                        if (localStorage.getItem('own-book') == 'true') {
                            const userType = localStorage.getItem('user-type');
                            
                            const formDataToBookEmail = {
                                recipientName: userData.name,
                                recipientEmail: userData.email,
                                sendOnDate: new Date(),
                                fromName: userData.name,
                                message: 'Self sent book',
                                userId: data.userId,
                                userType: userType
                            };
                            console.log(formDataToBookEmail);
                            await createBook(formDataToBookEmail);
                            localStorage.setItem('userId', data.userId);
                            navigate('/');
                        } else {
                            const formDataString = localStorage.getItem('form-data');
                            const formData = JSON.parse(formDataString);
                            const userType = localStorage.getItem('user-type');
                            
                            const formDataToBookEmail = {
                                recipientName: formData.recipientName,
                                recipientEmail: formData.recipientEmail,
                                sendOnDate: formData.sendOnDate,
                                fromName: formData.fromName,
                                message: formData.message,
                                userId: data.userId,
                                userType: userType
                            };
                            console.log(formDataToBookEmail);
                            await createBook(formDataToBookEmail);
                            localStorage.setItem('userId', data.userId);
                            navigate('/');
                        }
                    } catch (error) {
                        console.error('Error:', error);
                        toast.error(t('pages.register.finish_register.error_registering_in_system'))
                        setLoading(false);
                        navigate('/');
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoading(false);
                });
            }
        });
    };

    return (
        <div className="login-container">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-8 col-md-6 col-lg-4 pt-4 pb-4" style={{ textAlign: 'left', fontFamily: "'Rubik', serif" }}>
                    <h2 className="mb-3" style={{ color: '#396063', fontFamily: "'Frank Ruhl Libre', serif"}}>{t('pages.register.finish_register.get_our_saga_subscription')}</h2>
                    <hr />
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="amountOfBooksSelect" className="form-label" style={{ fontSize: '18px' }}>{t('pages.register.finish_register.amount_of_books')}</label>
                            <select className="form-control" id="amountOfBooksSelect" required value={amountOfBooks} onChange={(e) => setAmountOfBooks(e.target.value)}>
                                <option value="1">{t('pages.register.finish_register.1_book')}</option>
                                <option value="2">{t('pages.register.finish_register.2_books')}</option>
                                <option value="3">{t('pages.register.finish_register.3_books')}</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="fullNameInput" className="form-label" style={{ fontSize: '18px' }}>{t('pages.register.your_full_name_label')}</label>
                            <input type="text" className="form-control" id="fullNameInput" placeholder={t('pages.register.fullname_placeholder')} required value={fullName} onChange={handleFullNameChange} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="emailInput" className="form-label" style={{ fontSize: '18px' }}>{t('pages.register.your_email_address_label')}</label>
                            <input type="text" className="form-control" id="emailInput" placeholder={t('pages.register.email_placeholder')} required value={email} onChange={handleEmailChange} />
                            <div className="form-check mt-2">
                                <input type="checkbox" className="form-check-input" id="receiveOffers" checked={receiveOffers} onChange={(e) => setReceiveOffers(e.target.checked)} />
                                <label htmlFor="receiveOffers" className="form-check-label" style={{ fontSize: '12px' }}>{t('pages.register.want_receive_offers')}</label>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="passwordInput">{t('pages.register.password_label')}</label>
                            <input type="password" className="form-control" id="passwordInput" placeholder={t('pages.register.password_label')} value={password} onChange={handlePasswordChange} required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="confirmPasswordInput">{t('pages.register.confirm_password_label')}</label>
                            <input type="password" className="form-control" id="confirmPasswordInput" placeholder={t('pages.register.confirm_password_label')} value={confirmPassword} onChange={handleConfirmPasswordChange} required />
                        </div>
                        <div className="container p-1 text-center" style={{ backgroundColor: '#E9E2DA', fontFamily: '"Rubik", serif', fontSize: '13px', color: '#212529' }}>
                            <p>{t('pages.register.privacy_label')} <a href="/terms" style={{color: '#212529'}}>{t('pages.register.terms_service')}</a> {t('pages.register.and')} <a href="/privacy" style={{color: '#212529'}}>{t('pages.register.privacy_policy')}</a>.</p>
                        </div>
                        <button className="btn btn-primary mt-2" type="submit" style={{ backgroundColor: '#396063', fontFamily: "'Rubik', serif", width: '100%', borderRadius: '10px' }}>{t('pages.register.finish_register.purchase_button')}</button>
                    </form>
                </div>
            </div>
            <SatisfactionGuarantee />
            <ToastContainer />
        </div>
    )
}