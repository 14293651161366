import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { QuestionDashboard } from './pages/QuestionDashboard/QuestionDashboard';
import { EditQuestion } from './pages/Question/Edit/EditQuestion';
import { Login } from './pages/Login/Login';
import { Register } from './pages/Register/Register';
import { GiftReceiverInfo } from './pages/Register/GiftReceiverInfo';
import { FinishRegister } from './pages/Register/FinishRegister';
import { HomePage } from './pages/HomePage/HomePage';
import { ForgottenPassword } from './pages/ForgottenPassword/ForgottenPassword';
import { ForgottenPasswordReset } from './pages/ForgottenPassword/ForgottenPasswordReset';
import { ConfirmEmailGiftReceiver } from './pages/Register/ConfirmEmailGiftReceiver';
import { UnsubscribeFromEmails } from './pages/Unsubscribe/UnsubscribeFromEmails';
import { LogoHeader } from './components/common/LogoHeader/LogoHeader';
import { FromScratch } from './pages/Question/Add/FromScratch/FromScratch'
import './utils/translation/translationUtils';
import { ContactUs } from './pages/ContactUs/ContactUs';

function App() {
  return (
    <div className="App">
      <Router>
        <LogoHeader />
        <Routes>
          <Route exact path="/" element={<HomePage/>} />
          <Route path="/login" element={<Login/>} />
          <Route path="/register" element={<Register/>} />
          <Route path="/register/giftreceiverinfo" element={<GiftReceiverInfo/>} />
          <Route path="/register/finishregister" element={<FinishRegister/>} />
          <Route path="/register/confirmemail/:userId" element={<ConfirmEmailGiftReceiver/>}/>
          <Route path="/forgottenpassword" element={<ForgottenPassword/>} />
          <Route path="/forgottenpassword/reset/:email" element={<ForgottenPasswordReset/>} />
          <Route path="/book/:bookId" element={<QuestionDashboard/>} />
          <Route path="/question/add/:bookId" element={<FromScratch />} />
          <Route path="/question/edit/:questionId" element={<EditQuestion/>} />
          <Route path="/unsubscribe" element={<UnsubscribeFromEmails/>} />
          <Route path="/contactus" element={<ContactUs/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
