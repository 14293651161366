import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { confirmNewPassword, forgotPassword } from "../../utils/authentication/cognitoUtils";
import { useTranslation } from "react-i18next";

export const ForgottenPasswordReset = () => {

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();

    const navigate = useNavigate();

    let {email} = useParams();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            toast.error(t('pages.forgotten_password.reset.passwords_not_match'));
            return;
        }
        setLoading(true);
        confirmNewPassword(email, code, password, (err, data) => {
            if (err) {
                console.error(err);
                setLoading(false);
                if(err.code === 'InvalidPasswordException') {
                    toast.error(t('pages.forgotten_password.reset.invalid_password_error'));
                } else if (err.code === 'CodeMismatchException') {
                    toast.error(t('pages.forgotten_password.reset.code_missmatch'));
                } else if (err.code === 'LimitExceededException') {
                    toast.error(t('pages.forgotten_password.reset.limit_exceeded'));
                } else {
                    toast.error(t('pages.forgotten_password.reset.unknown_error'));
                }
            } else {
                console.log(data);
                setLoading(false);
                navigate('/login');
            }
        });
    }

    const resendCode = (e) => {
        e.preventDefault();
        setLoading(true);
        forgotPassword(email, (err, data) => {
            if (err) {
                console.error(err);
                setLoading(false);
                toast.error(t('pages.forgotten_password.reset.unknown_error'));
            } else {
                console.log(data);
                setLoading(false);
                toast.success(t('pages.forgotten_password.reset.email_resending_success'));
            }
        });
    }
    
    const handleCodeChange = (e) => {
        const writtenCode = e.target.value;
        setCode(writtenCode);
    };
    
    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        const newPassword = e.target.value;
        setConfirmPassword(newPassword);
    };


    return (
        <div className="login-container" style={{ backgroundColor: '#F8F4F1' }}>
            <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-4 offset-lg-4 pt-4 pb-4">
                <h2 className="mb-3" style={{ fontFamily: "'Frank Ruhl Libre', serif", color: '#396063', textAlign: 'left' }}>{t('pages.forgotten_password.reset.title')}</h2>
                <hr />
                <p style={{ fontFamily: "'Frank Ruhl Libre', serif", textAlign: 'left' }}>
                    {t('pages.forgotten_password.reset.email_sent')}
                    <br/>
                    {t('pages.forgotten_password.reset.not_received_code')} <a onClick={resendCode} href='#'>{t('pages.forgotten_password.reset.send_again')}</a>.
                </p>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3" style={{ textAlign: 'left', fontFamily: "'Frank Ruhl Libre', serif" }}>
                        <label htmlFor="codeInput">{t('pages.forgotten_password.reset.code')}</label>
                        <input
                        type="text"
                        className="form-control"
                        id="codeInput"
                        placeholder={t('pages.forgotten_password.reset.code')}
                        value={code}
                        onChange={handleCodeChange}
                        required
                        disabled={loading}
                        />
                    </div>
                    <div className="mb-3" style={{ textAlign: 'left', fontFamily: "'Frank Ruhl Libre', serif" }}>
                        <label htmlFor="passwordInput">{t('pages.forgotten_password.reset.password')}</label>
                        <input
                        type="password"
                        className="form-control"
                        id="passwordInput"
                        placeholder={t('pages.forgotten_password.reset.password')}
                        value={password}
                        onChange={handlePasswordChange}
                        required
                        disabled={loading}
                        />
                    </div>
                    <div className="mb-3" style={{ textAlign: 'left', fontFamily: "'Frank Ruhl Libre', serif" }}>
                        <label htmlFor="confirmPasswordInput">{t('pages.forgotten_password.reset.confirm_password')}</label>
                        <input
                        type="password"
                        className="form-control"
                        id="confirmPasswordInput"
                        placeholder={t('pages.forgotten_password.reset.confirm_password')}
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        required
                        disabled={loading}
                        />
                    </div>
                    <div className="d-grid gap-2">
                        <input
                        className="btn btn-primary"
                        type="submit"
                        style={{backgroundColor: '#396063', fontFamily: "'Frank Ruhl Libre', serif" }}
                        value={t('pages.forgotten_password.reset.change_password')}
                        disabled={loading || !email}
                        />
                    </div>
                </form>
            </div>
            <ToastContainer />
        </div>
    )
}