import React, { useEffect, useState } from "react";
import FacebookIcon from '../../assets/images/FacebookIcon.png';
import GoogleIcon from '../../assets/images/GoogleIcon.png';
import FirstRowIcon from '../../assets/images/FirstRowIcon.png';
import SecondRowIcon from '../../assets/images/SecondRowIcon.png';
import ThirdRowIcon from '../../assets/images/ThirdRowIcon.png';
import { finishChangePasswordRequired, getUserId, loginUser } from "../../utils/authentication/cognitoUtils";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Login.css';
import { useTranslation } from "react-i18next";



export const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [changePasswordFormVisible, setChangePasswordFormVisible] = useState(false);
    const [cognitoUserToChangePassword, setCognitoUserToChangePassword] = useState(false);
    const [newName, setNewName] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const {t} = useTranslation();
    const navigate = useNavigate();
    
    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
    };
    
    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleLogin(email, password);
    };
    
    const handleNewNameChange = (e) => {
        const newName = e.target.value;
        setNewName(newName);
    };
    
    const handleNewPasswordChange = (e) => {
        const newPassword = e.target.value;
        setNewPassword(newPassword);
    };
    
    const handleConfirmNewPasswordChange = (e) => {
        const confirmNewPassword = e.target.value;
        setConfirmNewPassword(confirmNewPassword);
    };

    const handleChangePasswordSubmit = (e) => {
        e.preventDefault();
        if (newPassword !== confirmNewPassword) {
            toast.error(t('pages.register.passwords_not_match'));
            return;
        }
        if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(newPassword)) {
            toast.error(t('pages.register.insecure_password'));
            return;
        }

        if (cognitoUserToChangePassword) {
            finishChangePasswordRequired(cognitoUserToChangePassword, newPassword, newName, (error, successMessage) => {
                if (error) {       
                    console.error(error);
                    toast.error(t('pages.login.password_update_error'));
                } else {
                    console.log(successMessage);
                    toast.success(t('pages.login.password_update_success'));
                    navigate('/');
                }
            });
        }
    };

    useEffect(() => {
        getUserId((err, userId) => {
            if(userId) {
                navigate('/');
            }
        });
      }, []);

    const handleLogin = (email, password) => {
        setLoading(true);
        loginUser(email, password, (err, cognitoId) => {
            if (err) {
                if (err == 'cognitoFailure'){ 
                    console.error(err);
                    setLoading(false);
                    toast.error(t('pages.login.incorrect_password_error'));
                } else if (err.newPasswordRequired) { 
                    console.error(err);
                    toast.info(t('pages.login.update_password_needed_info'));
                    setCognitoUserToChangePassword(err.user)
                    setChangePasswordFormVisible(true);
                    setLoading(false);
                } else {
                    console.error('Error al obtener el ID de usuario:', err);
                    toast.error(t('pages.login.no_user_in_db_error'));
                    setLoading(false);
                }
            } else {
                setLoading(false);
                navigate('/');                
            }
        });
    };

    const onGoogleLoginClick = () => {
        toast.warning(t('pages.login.unavailable_option'));
    };

    const onFacebookLoginClick = () => {
        toast.warning(t('pages.login.unavailable_option'));
    };
    

    return(
        <div className="login-container" style={{ backgroundColor: '#F8F4F1', paddingTop: '1.25%'}}>
            <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-4 offset-lg-4 pt-4 pb-4">
                <h2 className="mb-3" style={{ fontFamily: "'Frank Ruhl Libre', serif", color: '#396063', textAlign: 'left' }}>{t('pages.login.title')}</h2>
                <hr />

                {(changePasswordFormVisible) ? (
                    <form onSubmit={handleChangePasswordSubmit}>
                        <div className="mb-3" style={{ textAlign: 'left', fontFamily: "'Rubik', serif" }}>
                            <label htmlFor="newNameInput">{t('pages.login.new_name')}</label>
                            <input
                            type="text"
                            className="form-control"
                            id="newNameInput"
                            placeholder={t('pages.login.new_name')}
                            value={newName}
                            onChange={handleNewNameChange}
                            required
                            disabled={loading}
                            />
                        </div>
                        <div className="mb-3" style={{ textAlign: 'left', fontFamily: "'Rubik', serif" }}>
                            <label htmlFor="newPasswordInput">{t('pages.login.new_password')}</label>
                            <input
                            type="password"
                            className="form-control"
                            id="newPasswordInput"
                            placeholder={t('pages.login.new_password')}
                            value={newPassword}
                            onChange={handleNewPasswordChange}
                            required
                            disabled={loading}
                            />
                        </div>
                        <div className="mb-3" style={{ textAlign: 'left', fontFamily: "'Rubik', serif" }}>
                            <label htmlFor="confirmNewPasswordInput">{t('pages.login.confirm_new_password')}</label>
                            <input
                            type="password"
                            className="form-control"
                            id="confirmNewPasswordInput"
                            placeholder={t('pages.login.confirm_new_password')}
                            value={confirmNewPassword}
                            onChange={handleConfirmNewPasswordChange}
                            required
                            disabled={loading}
                            />
                        </div>
                        <div className="d-grid gap-2">
                            <input
                            className="btn btn-primary"
                            type="submit"
                            style={{ backgroundColor: '#396063', fontFamily: "'Rubik', serif", borderRadius: "10px"}}
                            value={t('pages.login.change_password_button')}
                            disabled={loading}
                            />
                        </div>
                    </form>
                ) : (
                    <form onSubmit={handleSubmit} style={{visibility: !changePasswordFormVisible}}>
                        <div className="mb-3" style={{ textAlign: 'left', fontFamily: "'Rubik', serif" }}>
                            <label htmlFor="emailInput">{t('pages.login.email')}</label>
                            <input
                            type="text"
                            className="form-control"
                            id="emailInput"
                            placeholder={t('pages.login.email')}
                            value={email}
                            onChange={handleEmailChange}
                            required
                            disabled={loading}
                            />
                        </div>
                        <div className="mb-3" style={{ textAlign: 'left', fontFamily: "'Rubik', serif" }}>
                            <label htmlFor="passwordInput">{t('pages.login.password')}</label>
                            <input
                            type="password"
                            className="form-control"
                            id="passwordInput"
                            placeholder={t('pages.login.password')}
                            value={password}
                            onChange={handlePasswordChange}
                            required
                            disabled={loading}
                            />
                        </div>
                        <div className="d-grid gap-2">
                            <input
                            className="btn btn-primary"
                            type="submit"
                            style={{ backgroundColor: '#396063', fontFamily: "'Rubik', serif", borderRadius: "10px"}}
                            value={t('pages.login.log_in_button')}
                            disabled={loading}
                            />
                        </div>
                    </form>
                )}

                <div className="text-center" style={{ fontFamily: "'Rubik', serif" }}>
                    <div className="mt-2">{t('pages.login.forgot_your_password')}</div>
                    <div>{t('pages.login.click')} <a href="/forgottenpassword" disabled={loading} style={{fontWeight:'bold', color:'black'}}>{t('pages.login.here')}</a> {t('pages.login.to_recover_it')}</div>
                </div>

                <div className="d-grid gap-2 mt-3">
                    <button
                        className="btn btn-light"
                        type="button"
                        onClick={() => onGoogleLoginClick()}
                        style={{ fontFamily: "'Rubik', serif" }}
                        disabled={loading}
                    >
                        <img src={GoogleIcon} alt="Google Icon" width="25" style={{ marginRight: '1%' }} />
                        {t('pages.login.log_in_with_google')}
                    </button>
                </div>
                <div className="d-grid gap-2 mt-2">
                    <button
                        className="btn btn-light"
                        type="button"
                        onClick={() => onFacebookLoginClick()}
                        style={{ fontFamily: "'Rubik', serif" }}
                        disabled={loading}
                    >
                        <img src={FacebookIcon} alt="Facebook Icon" width="25" style={{ marginRight: '1%' }} />
                        {t('pages.login.log_in_with_facebook')}
                    </button>
                </div>

                <div className="text-center" style={{ fontFamily: "'Rubik', serif" }}>
                    <div className="mt-3">{t('pages.login.not_have_account')} <a href="/register" disabled={loading} style={{fontWeight:'bold', color:'black'}}>{t('pages.login.sign_up')}</a></div>
                </div>

                <hr />

                {[FirstRowIcon, SecondRowIcon, ThirdRowIcon].map((icon, index) => (
                <div className="row align-items-center mt-3" key={index}>
                    <div className="col-3">
                        <img src={icon} alt="Icon" width="100" />
                    </div>
                    <div className="col-9">
                        <div className="row text-left">
                            <label style={{ textAlign: 'left', fontFamily: "'Rubik', serif"}}>
                                {index === 0 && t('pages.login.icons_row_1')}
                                {index === 1 && t('pages.login.icons_row_2')}
                                {index === 2 && t('pages.login.icons_row_3')}
                            </label>
                        </div>
                    </div>
                </div>
                ))}
            </div>
            <ToastContainer />
        </div>

    )
}