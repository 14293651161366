import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { MarkdownTextarea } from '../../../components/common/MarkdownTextarea/MarkdownTextarea';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@react-hook/media-query';
import { formatDistanceToNow } from 'date-fns';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import { es, nb, it, sv } from 'date-fns/locale';
import './EditQuestion.css';

export const EditQuestion = () => {
  const [question, setQuestion] = useState('');
  const {t} = useTranslation();
  const [bookId, setBookId] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [questionChanged, setQuestionChanged] = useState(false);
  const [editorStateChanged, setEditorStateChanged] = useState(false);
  const [lastSavedOn, setLastSavedOn] = useState(t('pages.question.never_saved'));
  const [lastSavedOnTime, setLastSavedOnTime] = useState(null);
  const timeoutIdRef = useRef(null);
  const navigate = useNavigate();
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  const [moreQuestionsEnabled, setMoreQuestionsEnabled] = useState(false);
  const [numberOfQuestionsToShow, setNumberOfQuestionsToShow] = useState(8);
  const isMobile = useMediaQuery('(max-width: 768px)'); 
  let { questionId } = useParams();

  const isDraftJsContent = (text) => {
    try {
      const jsonData = JSON.parse(text);
      if (jsonData.blocks && jsonData.entityMap !== undefined) {
        return true;
      }
    } catch (e) {
      if (text.includes('<') && text.includes('>')) {
        return false;
      }
    }
    return false;  
  }

  const htmlToDraft = (html) => {
    const contentState = stateFromHTML(html);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }

  const draftToHTML = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const html = stateToHTML(contentState);
    return html;
  }
  
  const onMoreQuestionsClick = (enabled) => {
    setMoreQuestionsEnabled(enabled);
    if(enabled) {
      setNumberOfQuestionsToShow(suggestedQuestions.length);
    } else {
      setNumberOfQuestionsToShow(isMobile ? 6 : 8);
    }
  };

  const fetchQuestions = async() => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/questions/suggested/${bookId}?lang=${localStorage.getItem('language') ? localStorage.getItem('language') : 'en'}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status} response: ${response.json()}`);
        }
        const questions = await response.json();
        return questions;
      } catch (error) {
        console.error("Fetching questions failed", error);
        throw error;
      }
  };

  useEffect(() => {
    setNumberOfQuestionsToShow(isMobile ? 6 : 8);
  }, [isMobile]);

  const getQuestions = async () => {
    try {
      const fetchedQuestions = await fetchQuestions();
      setSuggestedQuestions(fetchedQuestions);
    } catch (error) {
      toast.error(t('pages.question.could_not_fetch_our_questions'));
    }
  };

  useEffect(() => {
    getQuestions();
  }, [bookId, localStorage.getItem('language')]);

  useEffect(() => {
    const currentLocale = (userLanguage) => {
        switch (userLanguage) {
            case 'es':
                return es;
            case 'no':
                return nb;
            case 'it':
                return it;
            case 'sv':
                return sv;
            default:
                return undefined;
        }
    };

    if (lastSavedOnTime) {
      setLastSavedOn(`${t('pages.question.last_saved')} ${formatDistanceToNow(lastSavedOnTime, {
        addSuffix: true,
        locale: currentLocale(localStorage.getItem('language')) 
      })}.`);
    }
    const interval = setInterval(() => {
      if (lastSavedOnTime) {
        setLastSavedOn(`${t('pages.question.last_saved')} ${formatDistanceToNow(lastSavedOnTime, {
          addSuffix: true,
          locale: currentLocale(localStorage.getItem('language')) 
        })}.`);
      }
    }, 60000);
  
    return () => clearInterval(interval); 
  }, [lastSavedOnTime]);

  const addSuggestedQuestion = (index) => {
    const selectedQuestion = suggestedQuestions[index];
    setSuggestedQuestions(null);    

    handleQuestionChange({ target: { value: selectedQuestion.Question } });
  };


  useEffect(() => {
    const fetchData = async () => {
      console.log(questionId);
      try {
        const questionData = await fetchQuestion(questionId);
        setQuestion(questionData.Question);
        setBookId(questionData.BookId);
        
        if (questionData.Answer) {
          if (isDraftJsContent(questionData.Answer)) { 
            const contentState = convertFromRaw(JSON.parse(questionData.Answer));
            setEditorState(EditorState.createWithContent(contentState));
          } else {
            setEditorState(htmlToDraft(questionData.Answer));
          }
        }
        
      } catch (error) {
        console.error("Error loading question and answer", error);
      }
    };

    fetchData();
  }, [questionId]);

  useEffect(() => {
    const doAutoSave = () => {
      autoSaveQuestionAnswer();
    };
  
    clearTimeout(timeoutIdRef.current);
  
    timeoutIdRef.current = setTimeout(doAutoSave, 500);
  
    return () => clearTimeout(timeoutIdRef.current);
  }, [question, editorState]);

  const fetchQuestion = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/questions/` + questionId);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status} response: ${response.json()}`);
      }
      const question = await response.json();
      return question;
    } catch (error) {
      console.error("Fetching questions failed", error);
    }
  };

  const handleAnswerQuestion = async (questionId, question, editorState) => {
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);

    const rawContentString = JSON.stringify(rawContent);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/questions/update/${questionId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question: question, answer: rawContentString, state: contentState.hasText() ? 'Draft' : 'Not started' }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
  
      const data = await response.json();
      console.log(data.message);
      navigate('/book/' + bookId);
  
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const handleQuestionChange = (e) => {
    const value = e.target.value;
    
    if (typeof e === 'object' && e.hasOwnProperty('value')) {
      value = e.value;
    }

    if (question !== value) {
      setQuestion(value);
      setQuestionChanged(true);
    }
  };

  const handleEditorChange = (newEditorState) => {
    if (editorState.getCurrentContent() !== newEditorState.getCurrentContent()) {
      setEditorState(newEditorState);
      setEditorStateChanged(true);
    }
  };

  const autoSaveQuestionAnswer = async () => {
    if (!questionChanged && !editorStateChanged) return;

    const contentState = editorState.getCurrentContent();
    
    if (!question) return;
  
    var questionState;
    if (!contentState.hasText()) {
      questionState = 'Not started';
    } else {
      questionState = 'Draft';
    }
    
    try {
      setLastSavedOnTime(null);
      setLastSavedOn(t('pages.question.saving'));
  
      
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/questions/update/${questionId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question: question, answer: draftToHTML(editorState), state: questionState }),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('Auto-save successful:', data.message);
        setLastSavedOnTime(new Date());
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.error('Auto-save error:', error);
      setLastSavedOnTime(null);
      setLastSavedOn(t('pages.question.error_while_saving'));
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  
  const onError = (errorMessage) => {
    toast.error(errorMessage);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  return (
    <div className="container" style={{paddingTop: '2.5%'}}>
      <div className="row">
        <div className='col-md-2'></div>
        <div className="col-md-7 pb-4">
          <div className='row text-left' style={{textAlign:'left', fontFamily: "'Rubik', serif"}}>
            <div className="mb-3" style={{textAlign:'left', fontFamily: "'Rubik', serif"}}>
              <div style={{ position: 'relative' }}>
                <input
                  type="text"
                  className="form-control form-control-custom"
                  id="questionInput"
                  placeholder={t('pages.question.question_placeholder')}
                  value={question}
                  onChange={handleQuestionChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  style={{fontSize: '30px'}} 
                />
                {!isFocused && question && (
                  <i className="fas fa-pencil-square edit-icon"></i>
                )}
              </div>
            </div>
            <div style={{textAlign:'left', fontFamily: "'Rubik', serif", fontSize: '18px', marginBottom: suggestedQuestions ? '1rem' : '20rem'}}>
              <MarkdownTextarea
              placeholder={t('pages.question.answer_placeholder')}
              editorState={editorState}
              onChange={handleEditorChange}
              setEditorState={setEditorState}
              onError={onError}
              onFocus={() => setSuggestedQuestions(null)}
              onBlur={() => getQuestions()}
              />
            </div>
          </div>
          {(suggestedQuestions?.length > 0) ? (
          <div className='row text-left' style={{textAlign:'left', fontFamily: "'Rubik', serif"}}>
            <h6 className='mt-2' style={{fontFamily: "'Rubik', serif", textAlign:'left', fontWeight:'bold'}}>... {t('pages.question.or') + ' ' + t('pages.question.our_questions').toLocaleLowerCase() + ':'}</h6>
            <ul style={{listStyle: 'none'}}>
              {suggestedQuestions?.slice(0, numberOfQuestionsToShow).map((suggestedQuestion, index) => (
                <a href='#' className='suggested-question mt-1' onClick={() => addSuggestedQuestion(index)}><li key={index} className="suggested-question mt-1">
                  {suggestedQuestion.Question}
                </li></a>
              ))}
            </ul>
            
            {!moreQuestionsEnabled && suggestedQuestions?.length > numberOfQuestionsToShow && (
              <a href='#' className='more-questions' onClick={() => onMoreQuestionsClick(true)}><i className='fas fa-arrow-down fa-xs' style={{marginRight: '5px'}}></i>{t('pages.question.more_questions')}...</a>
            )}
            {moreQuestionsEnabled && (
              <a href='#' className='more-questions' onClick={() => onMoreQuestionsClick(false)}><i className='fas fa-arrow-up fa-xs' style={{marginRight: '5px'}}></i>{t('pages.question.less_questions')}</a>
            )}
          </div> ) : (
          <div className='row text-left' style={{textAlign:'left', fontFamily: "'Rubik', serif"}}>
            <a style={{fontFamily: "'Rubik', serif", textAlign:'left', fontWeight:'bold', color:'#396063'}} href='#' onClick={() => getQuestions()}><h6 className='mt-2' style={{fontFamily: "'Rubik', serif", textAlign:'left', fontWeight:'bold'}}>{capitalizeFirstLetter(t('pages.question.our_questions'))}</h6></a>
          </div>
          )
          }
          
        </div>
        <div className='col-md-3'>
          <div className={!isMobile || suggestedQuestions ? 'row mt-4 pt-3' : 'row pt-3'}>
            <div className='col-6' >
              <div className='row' style={{paddingRight: '2.5px'}}>
                <button
                className="btn btn-light savebutton"
                type="button"
                onClick={() => autoSaveQuestionAnswer()}
                style={{fontSize: '14px', borderWidth: '2px', color: 'gray', borderColor: 'gray', borderWidth: '1px', fontFamily: 'Rubik, serif', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '0px', paddingRight: '0px'}}
                >
                  {t('pages.question.save_button')}
                </button>
              </div>
            </div>
            <div className='col-6' >
              <div className='row' style={{paddingLeft: '2.5px'}}>
                <button
                className="btn btn-light"
                type="button"
                onClick={() => handleAnswerQuestion(questionId, question, editorState)}
                style={{fontSize: '14px', backgroundColor: '#396063', fontFamily: 'Rubik, serif', color: 'white', borderWidth: '1px', borderColor: '#396063', paddingLeft: '0', paddingRight: '0', paddingTop: '10px', paddingBottom: '10px'}}
                disabled = {!questionId && !editorState.getCurrentContent().hasText()}
                >
                  {t('pages.question.save_exit_button')}
                </button>
              </div>
            </div>
          </div>
          <div className='row'>
          <p className='mt-3' style={{fontFamily: "'Rubik', serif"}}>{lastSavedOn}</p>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
}