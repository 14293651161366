import React, { useEffect, useState } from 'react';
import { AtomicBlockUtils, EditorState, Modifier, RichUtils, getDefaultKeyBinding } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createImagePlugin from '@draft-js-plugins/image';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/image/lib/plugin.css'; 
import './MarkdownTextarea.css';
import { useTranslation } from 'react-i18next';

const speechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

export const MarkdownTextarea = ({placeholder, editorState, onChange, setEditorState, disabled, onError, onBlur, onFocus}) => {
  const {t} = useTranslation();
  const imagePlugin = createImagePlugin();
  const plugins = [imagePlugin];

  const [isListening, setIsListening] = useState(false);
  const [currentHeader, setCurrentHeader] = useState('text');
  const recognition = new speechRecognition();
  recognition.continuous = true;
  recognition.interimResults = true;
  recognition.lang = localStorage.getItem("language");
  
  recognition.onresult = (event) => {
    const transcript = Array.from(event.results)
      .map((result) => result[0])
      .map((result) => result.transcript)
      .join('');
    
    if (editorState) {
      const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        transcript,
        editorState.getCurrentInlineStyle(),
      );
      setEditorState(EditorState.push(editorState, contentState, 'insert-characters'));
    }
  };

  const toggleRecording = (e) => {
    e.preventDefault();
    
    console.log("Attempting to toggle recording...");
    console.log(localStorage.getItem("language"));
    console.log(recognition.lang);
    if (isListening) {
      console.log("Trying to stop recording.");
      setIsListening(false);
      console.log("IsListening: " + isListening);
    } else {
      try {
        console.log("Trying to start recording.");
        setIsListening(true);
        console.log("IsListening: " + isListening);
      } catch (error) {
        console.log("An error ocurred when trying to start recording.");
        setIsListening(false);
        console.log("IsListening: " + isListening);
        console.error(error);
        if (error.name === 'NotAllowedError') {
          onError(t('components.common.markdown_text_area.microphone_not_allowed_error'));
        } else {
          onError(t('components.common.markdown_text_area.microphone_unknown_error'));
        }
      }
    }
  };

  useEffect(() => {
    if (isListening) {
      recognition.start();
      recognition.onend = () => recognition.start();
    } else {
      recognition.stop();
      recognition.onend = null;
    }

    return () => {
      recognition.stop();
      recognition.onend = null;
    };
  }, [isListening]);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const [isDropdownListOpen, setIsDropdownListOpen] = useState(false);

  const toggleDropdownList = () => setIsDropdownListOpen(!isDropdownListOpen);

  const handleKeyCommand = async (command, editorState, { setEditorState }) => {
    if (command.includes('delete-me')) {
      const selection = editorState.getSelection();
      if (!selection.isCollapsed()) {
        return 'not-handled';
      }
  
      const contentState = editorState.getCurrentContent();
      const blockKey = selection.getStartKey();
      const block = contentState.getBlockForKey(blockKey);
  
      if (block.getType() === 'atomic') {
        await removeImage(blockKey);
        return 'handled';
      }
  
      const adjacentBlockKey = command === 'delete-me-before' ? contentState.getKeyBefore(blockKey) : contentState.getKeyAfter(blockKey);
      const adjacentBlock = contentState.getBlockForKey(adjacentBlockKey);
  
      if (adjacentBlock && adjacentBlock.getType() === 'atomic') {
        await removeImage(adjacentBlockKey);
        return 'handled';
      }
    }
  
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
  
    return 'not-handled';
  };
  

  const undoDisabled = editorState.getUndoStack().size === 0;
  const redoDisabled = editorState.getRedoStack().size === 0;


  const applyInlineStyle = (style) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };

  const onBulletListClick = () => {
    setEditorState(RichUtils.toggleBlockType(editorState, 'unordered-list-item'));
  };

  const onOrderedListClick = () => {
    setEditorState(RichUtils.toggleBlockType(editorState, 'ordered-list-item'));
  };

  const handleUndo = () => {
    setEditorState(EditorState.undo(editorState));
  };

  const handleRedo = () => {
    setEditorState(EditorState.redo(editorState));
  };

  const applyHeaderStyle = (headerType) => {
    setEditorState(RichUtils.toggleBlockType(editorState, headerType));
    setCurrentHeader(headerType);
  };

  const removeHeaderStyle = () => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const blockType = contentState.getBlockForKey(selection.getStartKey()).getType();
    if (blockType.startsWith('header-')) {
      applyHeaderStyle('unstyled');
      setCurrentHeader('text');
    }
  };
  
  const updateCurrentHeader = () => {
    const selection = editorState.getSelection();
    const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();
    if (blockType.startsWith('header-')) {
      setCurrentHeader(blockType);
    } else {
      setCurrentHeader('text');
    }
  };

  const currentStyle = editorState.getCurrentInlineStyle();

  const isStyleActive = (style) => {
    return currentStyle.has(style);
  };

  const addImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
  
    input.onchange = async () => {
      const file = input.files[0];
      if (file) {
        try {
          const formData = new FormData();
          formData.append('image', file);
  
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/uploadimage`, {
            method: 'POST',
            body: formData,
          });
  
          const data = await response.json();
          if(!data.error && data.imageUrl) {
            const imageUrl = data.imageUrl;
            const contentState = editorState.getCurrentContent();
            const contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', { src: imageUrl });
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
            setEditorState(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '));
          } else {
            if(response.status === 413) {
              onError(t('components.common.markdown_text_area.image_size_error'));
            } else {
              console.log(response);
              onError(t('components.common.markdown_text_area.loading_images_error'));
            }
          }
        } catch (error) {
          console.error('Error loading image:', error);
        }
      }
    };
  };

  const removeImage = async (blockKey) => {
    const contentState = editorState.getCurrentContent();
    const block = contentState.getBlockForKey(blockKey);
    const entityKey = block.getEntityAt(0);

    const imageUrl = contentState.getEntity(entityKey).getData().src;

    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/deleteimage`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ imageUrl }),
        });

        if (!response.ok) {
            throw new Error('Failed to delete image from the server');
        }

        console.log('Image deleted from the server successfully');

    } catch (error) {
        console.error('Error deleting image:', error);
    }
    const newBlockMap = contentState.getBlockMap().delete(blockKey);
    const newContentState = contentState.set('blockMap', newBlockMap);
    const newEditorState = EditorState.push(editorState, newContentState, 'remove-range');
    setEditorState(newEditorState);
};

const keyBindingFn = (e) => {
  const selection = editorState.getSelection();
  if (!selection.isCollapsed()) {
    return getDefaultKeyBinding(e);
  }

  const contentState = editorState.getCurrentContent();
  const blockKey = selection.getStartKey();
  const block = contentState.getBlockForKey(blockKey);

  if (e.keyCode === 8 /* Backspace */) {
    const startOffset = selection.getStartOffset();
    if (startOffset === 0) {  // Only if at the start of a block
      const previousBlockKey = contentState.getKeyBefore(blockKey);
      if (previousBlockKey) {
        const previousBlock = contentState.getBlockForKey(previousBlockKey);
        if (previousBlock.getType() === 'atomic') {
          return 'delete-me-before';
        }
      }
    }
  } else if (e.keyCode === 46 /* Delete */) {
    const endOffset = selection.getEndOffset();
    if (endOffset === block.getLength()) {  // Only if at the end of a block
      const nextBlockKey = contentState.getKeyAfter(blockKey);
      if (nextBlockKey) {
        const nextBlock = contentState.getBlockForKey(nextBlockKey);
        if (nextBlock.getType() === 'atomic') {
          return 'delete-me-after';
        }
      }
    }
  }

  return getDefaultKeyBinding(e);
};


  useEffect(() => {
    updateCurrentHeader();
  }, [editorState]);
  
  return (
    <div>
      <div className="editor-toolbar">
        <button className="editor-toolbar-button" onMouseDown={(e) => { e.preventDefault(); handleUndo();}} disabled={undoDisabled}><i className="fas fa-undo fa-sm"></i></button>
        <button className="editor-toolbar-button" onMouseDown={(e) => { e.preventDefault(); handleRedo();}} disabled={redoDisabled}><i className="fas fa-redo fa-sm"></i></button>
        <div className="dropdown">
          <button className="editor-toolbar-button" onClick={toggleDropdown}>{t(`components.common.markdown_text_area.${currentHeader}`)} <i className="fa fa-caret-down fa-sm"></i></button>
          {isDropdownOpen && (
            <div className="dropdown-content">
              <button onMouseDown={(e) => { e.preventDefault(); applyHeaderStyle('header-one'); toggleDropdown(); }}>{t('components.common.markdown_text_area.header-one')}</button>
              <button onMouseDown={(e) => { e.preventDefault(); applyHeaderStyle('header-two'); toggleDropdown(); }}>{t('components.common.markdown_text_area.header-two')}</button>
              <button onMouseDown={(e) => { e.preventDefault(); applyHeaderStyle('header-three'); toggleDropdown(); }}>{t('components.common.markdown_text_area.header-three')}</button>
              <button onMouseDown={(e) => { e.preventDefault(); removeHeaderStyle(); toggleDropdown(); }}>{t('components.common.markdown_text_area.text')}</button>
            </div>
          )}
        </div>
        <button className={`editor-toolbar-button ${isStyleActive('BOLD') ? 'active' : ''}`} onMouseDown={(e) => { e.preventDefault(); applyInlineStyle('BOLD'); }}><i className="fas fa-bold fa-sm"></i></button>
        <button className={`editor-toolbar-button ${isStyleActive('ITALIC') ? 'active' : ''}`} onMouseDown={(e) => { e.preventDefault(); applyInlineStyle('ITALIC'); }}><i className="fas fa-italic fa-sm"></i></button>
        <button className={`editor-toolbar-button ${isStyleActive('UNDERLINE') ? 'active' : ''}`} onMouseDown={(e) => { e.preventDefault(); applyInlineStyle('UNDERLINE'); }}><i className="fas fa-underline fa-sm"></i></button>
        <div className="dropdown">
          <button className="editor-toolbar-button" onClick={toggleDropdownList}><i className="fas fa-list fa-sm"></i> <i className="fa fa-caret-down fa-sm"></i></button>
          {isDropdownListOpen && (
            <div className="dropdown-content">
              <button onMouseDown={(e) => { e.preventDefault(); onBulletListClick(); toggleDropdownList();}}><i className="fas fa-list"></i></button>
              <button onMouseDown={(e) => { e.preventDefault(); onOrderedListClick(); toggleDropdownList();}}><i className="fas fa-list-ol"></i></button>
            </div>
          )}
        </div>
        <button className="editor-toolbar-button" onMouseDown={(e) => { e.preventDefault(); addImage(); }}><i className="far fa-images fa-sm"></i></button>
        { speechRecognition &&
          <button
            className="editor-toolbar-button"
            onMouseDown={toggleRecording}
          >
            {isListening ? <i className="fas fa-microphone-slash"></i> : <i className="fas fa-microphone fa-sm"></i>}
          </button>
        }
      </div>
            
      <div className='editor-container mb-4 mt-4'>
          <Editor
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={keyBindingFn}
          onChange={onChange}
          placeholder={placeholder}
          readOnly={disabled}
          plugins={plugins}
          onBlur={onBlur}
          onFocus={onFocus}
          />
      </div>
    </div>
  );
};