import React, { useState, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { QuestionList } from '../../components/questionDashboard/QuestionList/QuestionList';
import { ToastContainer, toast } from 'react-toastify';
import { Header } from '../../components/questionDashboard/Header/Header';
import './QuestionDashboard.css';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

const initialState = {
  questions: [],
  counts: { notStarted: 0, drafts: 0, completed: 0 }
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_QUESTIONS':
      return {
        ...state,
        questions: action.payload,
        counts: {
          notStarted: action.payload?.filter(q => q.State.toUpperCase() === 'NOT STARTED').length,
          drafts: action.payload?.filter(q => q.State.toUpperCase() === 'DRAFT').length,
          completed: action.payload?.filter(q => q.State.toUpperCase() === 'COMPLETED').length
        }
      };
    case 'DELETE_QUESTION':
      const updatedQuestions = state.questions?.filter(q => q.Id !== action.payload);
      return {
        ...state,
        questions: updatedQuestions,
        counts: {
          notStarted: updatedQuestions.filter(q => q.State.toUpperCase() === 'NOT STARTED').length,
          drafts: updatedQuestions.filter(q => q.State.toUpperCase() === 'DRAFT').length,
          completed: updatedQuestions.filter(q => q.State.toUpperCase() === 'COMPLETED').length
        }
      };
    case 'REORDER_QUESTIONS':
      return {
        ...state,
        questions: action.payload,
      };
    case 'UPDATE_QUESTION_DATE':
      const updatedQuestionsWithDate = state.questions.map(question =>
        question.Id === action.payload.questionId ? { ...question, MailDeliveryDate: action.payload.newDate } : question
      );
      return {
        ...state,
        questions: updatedQuestionsWithDate,
      };
    case 'MARK_QUESTION_COMPLETED':
      const questionsWithUpdatedState = state.questions.map(question =>
        question.Id === action.payload.questionId ? { ...question, State: action.payload.newState } : question
      );
    
      return {
        ...state,
        questions: questionsWithUpdatedState,
        counts: {
          notStarted: questionsWithUpdatedState.filter(q => q.State.toUpperCase() === 'NOT STARTED').length,
          drafts: questionsWithUpdatedState.filter(q => q.State.toUpperCase() === 'DRAFT').length,
          completed: questionsWithUpdatedState.filter(q => q.State.toUpperCase() === 'COMPLETED').length
        }
      };      
    default:
      return state;
  }
}

export const QuestionDashboard = () => {

  let { bookId } = useParams();

  const [activeTab, setActiveTab] = useState('Not started');
  const [sagaName, setSagaName] = useState('');
  const [state, dispatch] = useReducer(reducer, initialState);
  const {t} = useTranslation();

  const toastError = (message) => {toast.error(message)};
  const toastSuccess = (message) => {toast.success(message)};

  const updateQuestionsDate = (questionId, newDate) => {
    console.log('Updating in Local, Id: ' + questionId + ', newDate: ' + newDate);
    dispatch({ type: 'UPDATE_QUESTION_DATE', payload: { questionId, newDate } });
  }

  const reorderQuestions = (items) => {
    dispatch({ type: 'REORDER_QUESTIONS', payload: items });
  }  
  
  const fetchSagaName = async (bookId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/books/sagaName/${bookId}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status} response: ${response.json()}`);
      }
      const sagaName = await response.json();
      console.log(sagaName);
      return sagaName;
    } catch (error) {
      console.error("Fetching saga name failed", error);
    }
  };

  const fetchQuestions = async (bookId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/questions/byBook/${bookId}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status} response: ${response.json()}`);
      }
      const questions = await response.json();
      return questions;
    } catch (error) {
      console.error("Fetching questions failed", error);
    }
  };

  useEffect(() => {
    const getSagaName = async () => {
      try {
        const sagaName = await fetchSagaName(bookId);
        setSagaName(sagaName.sagaName);
      } catch (error) {
        console.error(error);
      }
    };
    const getQuestions = async () => {
      try {
        const fetchedQuestions = await fetchQuestions(bookId);
        dispatch({ type: 'SET_QUESTIONS', payload: fetchedQuestions });
      } catch (error) {
        console.error(error);
      }
    };

    getSagaName();
    getQuestions();
  }, [bookId]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    const getQuestions = async () => {
      try {
        const fetchedQuestions = await fetchQuestions(bookId);
        dispatch({ type: 'SET_QUESTIONS', payload: fetchedQuestions });
      } catch (error) {
        console.error(error);
      }
    };

    getQuestions();
  };

  const deleteQuestion = async (questionId) => {
    Swal.fire({
      title: t('components.question_dashboard.question_list.are_you_sure'),
      text: t('components.question_dashboard.question_list.permanent_change'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('components.question_dashboard.question_list.confirm'),
      cancelButtonText: t('components.question_dashboard.question_list.cancel'),
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (questionId) {
          try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/questions/${questionId}`, {
              method: 'DELETE',
            });
        
            if (!response.ok) {
              throw new Error('Network response was not ok.');
            }
        
            await response.json();
            Swal.fire(
              t('components.question_dashboard.question_list.question_removed'),
              t('components.question_dashboard.question_list.question_removed_success'),
              'success'
            )
            dispatch({ type: 'DELETE_QUESTION', payload: questionId });
          } catch (error) {
            console.error('Error:', error.message);
            Swal.fire(
              t('components.question_dashboard.question_list.error'),
              t('components.question_dashboard.question_list.question_removed_fail'),
              'error'
            )
          }
        }
      }
    });
    
  };

  const markQuestionCompleted = async (questionId) => {
    if (questionId) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/questions/update/${questionId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ state: 'Completed' }),
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }
    
        const data = await response.json();
        console.log(data.message);    
        toast.success(t('components.question_dashboard.question_list.question_completed_success'));
        dispatch({ type: 'MARK_QUESTION_COMPLETED', payload: {questionId, newState: 'Completed'} });
      } catch (error) {
        console.error('Error:', error.message);
        toast.error(t('pages.question.add.from_scratch.question_completed_fail'));
      }
    }
  };

  return (
    <div className="dashboard main-container" style={{paddingTop: '1.25%'}}>
      <Header onTabChange={handleTabClick} activeTab={activeTab} sagaName={sagaName} bookId={bookId} questionsCount={state.counts}/>
      <QuestionList questions={state.questions?.filter(q => q.State.toUpperCase() === activeTab.toUpperCase())} deleteQuestion={deleteQuestion} activeTab={activeTab} reorderQuestions={reorderQuestions} updateQuestionsDate={updateQuestionsDate} toastSuccess={toastSuccess} toastError={toastError} markQuestionCompleted={markQuestionCompleted}></QuestionList>
      <ToastContainer/>
    </div>
  );
};