import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams }  from 'react-router-dom';
import './FromScratch.css';
import { MarkdownTextarea } from '../../../../components/common/MarkdownTextarea/MarkdownTextarea';
import { EditorState, convertToRaw } from 'draft-js';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {useMediaQuery} from '@react-hook/media-query';
import { formatDistanceToNow } from 'date-fns';
import { es, nb, it, sv } from 'date-fns/locale';
import { stateToHTML } from 'draft-js-export-html';


export const FromScratch = () => {
  const [question, setQuestion] = useState('');
  const [questionId, setQuestionId] = useState(null);
  const {t} = useTranslation();
  const [lastSavedOnTime, setLastSavedOnTime] = useState(null);
  const [lastSavedOn, setLastSavedOn] = useState(t('pages.question.never_saved'));
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const timeoutIdRef = useRef(null);
  const questionInputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  const [moreQuestionsEnabled, setMoreQuestionsEnabled] = useState(false);
  const [numberOfQuestionsToShow, setNumberOfQuestionsToShow] = useState(8);
  const isMobile = useMediaQuery('(max-width: 768px)'); 
  const navigate = useNavigate();

  let { bookId } = useParams();

  const id = parseInt(bookId, 10);
  
  const onMoreQuestionsClick = (enabled) => {
    setMoreQuestionsEnabled(enabled);
    if(enabled) {
      setNumberOfQuestionsToShow(suggestedQuestions.length);
    } else {
      setNumberOfQuestionsToShow(isMobile ? 6 : 8);
    }
  };

  
  const draftToHTML = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const html = stateToHTML(contentState);
    return html;
  }

  const fetchQuestions = async() => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/questions/suggested/${bookId}?lang=${localStorage.getItem('language') ? localStorage.getItem('language') : 'en'}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status} response: ${response.json()}`);
        }
        const questions = await response.json();
        return questions;
      } catch (error) {
        console.error("Fetching questions failed", error);
        throw error;
      }
  };

  useEffect(() => {
    setNumberOfQuestionsToShow(isMobile ? 6 : 8);
  }, [isMobile]);
  
  const getQuestions = async () => {
    try {
      const fetchedQuestions = await fetchQuestions();
      setSuggestedQuestions(fetchedQuestions);
    } catch (error) {
      toast.error(t('pages.question.could_not_fetch_our_questions'));
    }
  };

  useEffect(() => {
    getQuestions();
  }, [bookId, localStorage.getItem('language')]);
  
  useEffect(() => {
    const currentLocale = (userLanguage) => {
        switch (userLanguage) {
            case 'es':
                return es;
            case 'no':
                return nb;
            case 'it':
                return it;
            case 'sv':
                return sv;
            default:
                return undefined;
        }
    };

    if (lastSavedOnTime) {
      setLastSavedOn(`${t('pages.question.last_saved')} ${formatDistanceToNow(lastSavedOnTime, {
        addSuffix: true,
        locale: currentLocale(localStorage.getItem('language')) 
      })}.`);
    }
    const interval = setInterval(() => {
      if (lastSavedOnTime) {
        setLastSavedOn(`${t('pages.question.last_saved')} ${formatDistanceToNow(lastSavedOnTime, {
          addSuffix: true,
          locale: currentLocale(localStorage.getItem('language')) 
        })}.`);
      }
    }, 60000);
  
    return () => clearInterval(interval); 
  }, [lastSavedOnTime]);
  

  const addSuggestedQuestion = (index) => {
    const selectedQuestion = suggestedQuestions[index];
    setQuestion(selectedQuestion.Question);
    setSuggestedQuestions(null);    

    handleAddOrEditQuestion({ target: { value: selectedQuestion.Question } });
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const onError = (errorMessage) => {
    toast.error(errorMessage);
  };

  const handleAddOrEditQuestion = async (e) => {
    const value = e.target.value;

    if (typeof e === 'object' && e.hasOwnProperty('value')) {
      value = e.value;
    }

    setQuestion(value);

    if (!value) {
      return;
    }

    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    const timeoutId = setTimeout(async () => {
      try {
        setLastSavedOnTime(null);
        setLastSavedOn(t('pages.question.saving'));
        const response = await addOrEditQuestion(value);

        const { questionId: newQuestionId } = response;
        setQuestionId(newQuestionId);

        setLastSavedOnTime(new Date());
      } catch (error) {
        setLastSavedOnTime(null);
        setLastSavedOn(t('pages.question.error_while_saving'));
      }
    }, 500);

    timeoutIdRef.current = timeoutId;
  };

  const addOrEditQuestion = async (value) => {
    let response;
    try {
      if (questionId) {
        response = await updateQuestion(questionId, value);
      } else {
        response = await addQuestion(value);
      }
      return response;
    } catch(error) {
      console.error("Error: ", error);
      setLastSavedOnTime(null);
      setLastSavedOn(t('pages.question.error_while_saving'));
    }
  };

  const addQuestion = async (value) => {
    const contentState = editorState.getCurrentContent();    

    var state;
    if (contentState.hasText()) {
      state = 'Draft';
    } else {
      state = 'Not started';
    }

    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/questions/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ bookId: id, question: value, answer: draftToHTML(editorState), state: state }),
    });
    if (!response.ok) {
      throw new Error('Failed to add question');
    }
    return response.json();
  };

  const updateQuestion = async (questionId, value) => {
    const contentState = editorState.getCurrentContent();

    var state;
    if (contentState.hasText()) {
      state = 'Draft';
    } else {
      state = 'Not started';
    }
    
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/questions/update/${questionId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ question: value, answer: draftToHTML(editorState), state: state }),
    });
    if (!response.ok) {
      throw new Error('Failed to update question');
    }
    return response.json();
  };
  
  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
  
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    if (question) {
      timeoutIdRef.current = setTimeout(() => autoSaveAnswer(newEditorState), 500);
    } else {
      timeoutIdRef.current = setTimeout( () => {setLastSavedOnTime(null); setLastSavedOn(t('pages.question.add.from_scratch.answer_no_question'));}, 500);
    }
  };


  const autoSaveAnswer = async (editorState) => {
    const contentState = editorState.getCurrentContent();
    
    if (!contentState.hasText()) return;
  
    try {
      setLastSavedOnTime(null);
      setLastSavedOn(t('pages.question.saving'));
  
      
      if (questionId) {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/questions/update/${questionId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ question: question, answer: draftToHTML(editorState), state: 'Draft' }),
        });
    
        if (response.ok) {
          const data = await response.json();
          console.log('Auto-guardado exitoso:', data.message);
          setLastSavedOnTime(new Date());
        } else {
          throw new Error('Network response was not ok.');
        }
      } else {
        const response = await addQuestion(question);
        const { questionId: newQuestionId } = response;
        setQuestionId(newQuestionId);
      }
    } catch (error) {
      console.error('Auto-save error:', error);
      setLastSavedOnTime(null);
      setLastSavedOn(t('pages.question.error_while_saving'));
    }
  };

  const handleAnswerQuestion = async (questionId, question, editorState) => {
    const contentState = editorState.getCurrentContent();

    if (!question) {
      console.log(questionId);
      toast.error(t('pages.question.add.from_scratch.answer_no_question'));
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/questions/update/${questionId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question: question, answer: draftToHTML(editorState), state: contentState.hasText() ? 'Draft' : 'Not started'}),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
  
      const data = await response.json();
      console.log(data.message);
      navigate('/book/' + bookId);
  
    } catch (error) {
      console.error('Error:', error.message);
      toast.error(t('pages.question.add.from_scratch.unknown_error_answering'));
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  return (
    <div className="container" style={{paddingTop: '2.5%'}}>
      <div className="row">
        <div className='col-md-2'></div>
        <div className="col-md-7 pb-4">
          <div className='row text-left' style={{textAlign:'left', fontFamily: "'Rubik', serif"}}>
            <div className="mb-3" style={{textAlign:'left', fontFamily: "'Rubik', serif"}}>
              <div style={{ position: 'relative' }}>
                <input
                  type="text"
                  className="form-control form-control-custom"
                  id="questionInput"
                  placeholder={t('pages.question.question_placeholder')}
                  value={question}
                  onChange={handleAddOrEditQuestion}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  ref={questionInputRef}   
                  style={{fontSize: '30px'}}             
                />
                {!isFocused && question && (
                  <i className="fas fa-pencil-square edit-icon"></i>
                )}
              </div>
            </div>
            <div style={{textAlign:'left', fontFamily: "'Rubik', serif", fontSize: '18px', marginBottom: suggestedQuestions ? '1rem' : '20rem'}}>
              <MarkdownTextarea
              placeholder={t('pages.question.answer_placeholder')}
              editorState={editorState}
              onChange={handleEditorChange}
              setEditorState={setEditorState}
              disabled={!question}
              onError={onError}
              onFocus={() => setSuggestedQuestions(null)}
              onBlur={() => getQuestions()}             
              />
            </div>
          </div>
          {(suggestedQuestions?.length > 0) ? (
          <div className='row text-left' style={{textAlign:'left', fontFamily: "'Rubik', serif"}}>
            <h6 className='mt-2' style={{fontFamily: "'Rubik', serif", textAlign:'left', fontWeight:'bold'}}>... {t('pages.question.or') + ' ' + t('pages.question.our_questions').toLocaleLowerCase() + ':'}</h6>
            <ul style={{listStyle: 'none'}}>
              {suggestedQuestions?.slice(0, numberOfQuestionsToShow).map((suggestedQuestion, index) => (
                <a href='#' className='suggested-question mt-1' onClick={() => addSuggestedQuestion(index)}><li key={index} className="suggested-question mt-1">
                  {suggestedQuestion.Question}
                </li></a>
              ))}
            </ul>
            
            {!moreQuestionsEnabled && suggestedQuestions?.length > numberOfQuestionsToShow && (
              <a href='#' className='more-questions' onClick={() => onMoreQuestionsClick(true)}><i className='fas fa-arrow-down fa-xs' style={{marginRight: '5px'}}></i>{t('pages.question.more_questions')}...</a>
            )}
            {moreQuestionsEnabled && (
              <a href='#' className='more-questions' onClick={() => onMoreQuestionsClick(false)}><i className='fas fa-arrow-up fa-xs' style={{marginRight: '5px'}}></i>{t('pages.question.less_questions')}</a>
            )}
          </div> ) : (
          <div className='row text-left' style={{textAlign:'left', fontFamily: "'Rubik', serif"}}>
            <a style={{fontFamily: "'Rubik', serif", textAlign:'left', fontWeight:'bold', color:'#396063'}} href='#' onClick={() => getQuestions()}><h6 className='mt-2' style={{fontFamily: "'Rubik', serif", textAlign:'left', fontWeight:'bold'}}>{capitalizeFirstLetter(t('pages.question.our_questions'))}</h6></a>
          </div> )
          }
        </div>
        <div className='col-md-3'>
          <div className={!isMobile || suggestedQuestions ? 'row mt-4 pt-3' : 'row pt-3'}>
            <div className='col-6' >
              <div className='row' style={{paddingRight: '2.5px'}}>
                <button
                className="btn btn-light savebutton"
                type="button"
                onClick={() => autoSaveAnswer(editorState)}
                style={{fontSize: '14px', borderWidth: '2px', color: 'gray', borderColor: 'gray', borderWidth: '1px', fontFamily: 'Rubik, serif', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '0px', paddingRight: '0px'}}
                disabled = {!questionId}
                >
                  {t('pages.question.save_button')}
                </button>
              </div>
            </div>
            <div className='col-6'>
              <div className='row' style={{paddingLeft: '2.5px'}}>
                <button
                className="btn btn-light"
                type="button"
                onClick={() => handleAnswerQuestion(questionId, question, editorState)}
                style={{fontSize: '14px', backgroundColor: '#396063', fontFamily: 'Rubik, serif', color: 'white', borderWidth: '1px', borderColor: '#396063', paddingLeft: '0', paddingRight: '0', paddingTop: '10px', paddingBottom: '10px'}}
                disabled = {!questionId}
                >
                  {t('pages.question.save_exit_button')}
                </button>
              </div>
            </div>
          </div>
          <div className='row'>
          <p className='mt-3' style={{fontFamily: "'Rubik', serif"}}>{lastSavedOn}</p>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </div>

  );
};