import React, { useState } from "react";
import { SatisfactionGuarantee } from "../../components/register/SatisfactionGuarantee/SatisfactionGuarantee";
import { useNavigate } from "react-router-dom";
import './Register.css';
import { useTranslation } from "react-i18next";

export const Register = () => {
    const [userType, setUserType] = useState(null);
    const navigate = useNavigate();
    const {t} = useTranslation();

    const onContinueClicked = () => {
        if (userType) {
            localStorage.setItem('user-type', userType);
            navigate("/register/giftreceiverinfo");
        } else {
            alert(t('pages.register.first.choose_an_option'))
        }
    }

    return (
        <div className="login-container">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-8 col-md-6 col-lg-4 pt-4 pb-4" style={{ textAlign: 'left', fontFamily: "'Rubik', serif" }}>
                    <h2 className="mb-3" style={{ color: '#396063', textAlign: 'left', fontFamily: "'Frank Ruhl Libre', serif" }}>{t('pages.register.first.title')}</h2>
                    <hr />
                    <p>{t('pages.register.first.storyteller_title')}</p>
                    <div className="form-check pt-1 pb-1" style={{ borderStyle: 'solid', borderRadius: '10px', borderWidth: '1px', borderColor: '#C0A37F' }}>
                        <input className="form-check-input" type="radio" name="storytellerRadio" id="iWillOption" value={'storyteller'} style={{ marginLeft: '-0.75rem' }} onClick={() => setUserType('storyteller')} />
                        <label className="form-check-label" htmlFor="iWillOption" style={{ marginLeft: '0.5rem'}}>
                        {t('pages.register.first.storyteller_me')}
                        </label>
                    </div>
                    <div className="form-check mt-2 pt-1 pb-1" style={{ borderStyle: 'solid', borderRadius: '10px', borderWidth: '1px', borderColor: '#C0A37F' }}>
                        <input className="form-check-input" type="radio" name="storytellerRadio" id="someOneElseWillOption" value={'subscriber'} style={{ marginLeft: '-0.75rem' }} onClick={() => setUserType('subscriber')} />
                        <label className="form-check-label" htmlFor="someOneElseWillOption" style={{ marginLeft: '0.5rem' }}>
                        {t('pages.register.first.storyteller_someone_else')}
                        </label>
                    </div>
                    <button className="btn btn-primary mt-2" type="submit" style={{ backgroundColor: '#396063', fontFamily: "'Rubik', serif", width: '100%', borderRadius: '10px' }} disabled={userType == null} onClick={onContinueClicked}>{t('pages.register.continue_button')}</button>
                </div>
            </div>
            <SatisfactionGuarantee />
        </div>
        
        )
}